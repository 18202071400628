import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";

const ContactForm = () => {
  const [status, setStatus] = useState(undefined);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      name: forms.name,
      email: forms.email,
      phone: forms.phone,
      address: forms.address,
      message: forms.message,
    };
    setStatus({ type: "process" })
    await axios
      .post(`https://gtscatering.com/Email/ContactUs.php`, data)
      .then(
        (res) => {
        setStatus({ type: "success" })
        setTimeout(function () {
          window.location.reload(1);
        }, 3000)}
      )
      .catch((err) => console.log(err));
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      id="form"
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              id="Body"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Name"
              required
            />
            {/* {validator.message('name', forms.name, 'required|alpha_space')} */}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Email"
              required
            />
            {/* {validator.message('email', forms.email, 'required|email')} */}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your phone"
              required
            />
            {/* {validator.message('phone', forms.phone, 'required|phone')} */}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.address}
              type="text"
              name="address"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Address"
              required
            />
            {/* {validator.message('phone', forms.phone, 'required|phone')} */}
          </div>
        </div>

        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Comments"
            required
          ></textarea>
          {/* {validator.message('message', forms.message, 'required')} */}
        </div>
      </div>
      <div className="submit-area">
        <button
          type="submit"
          className={`theme-btn ${status?.type == "success" ? "disabled" : ""}`}
        >
          {" "}
          {status?.type === "process" ? "Submitting...":"Submit Now"}
        </button>
      </div>
      {status?.type === "success" && (
        <div className="submit-area">
          <a
            style={{ padding: "10px", fontSize: "20px", color: "green" }}
            className="submitSuccess"
          >
            {" "}
            Form Submitted successfully...
          </a>
        </div>
      )}
    </form>
  );
};

export default ContactForm;
