import React from "react";

import gallery1 from "../../images/logo/gallery/gallery1.JPG";
import gallery2 from "../../images/logo/gallery/gallery2.JPG";
import gallery3 from "../../images/logo/gallery/gallery3.JPG";
import gallery4 from "../../images/logo/gallery/gallery4.JPG";
import gallery5 from "../../images/logo/gallery/gallery5.JPG";
import gallery6 from "../../images/logo/gallery/gallery6.JPG";
import gallery7 from "../../images/logo/gallery/gallery7.JPG";
import gallery8 from "../../images/logo/gallery/gallery8.JPG";
import gallery9 from "../../images/logo/gallery/gallery9.JPG";
import gallery10 from "../../images/logo/gallery/gallery10.JPG";
import gallery11 from "../../images/logo/gallery/gallery11.JPG";
import gallery12 from "../../images/logo/gallery/gallery12.JPG";
import gallery13 from "../../images/logo/gallery/gallery13.JPG";
import gallery14 from "../../images/logo/gallery/gallery14.JPG";
import gallery15 from "../../images/logo/gallery/gallery15.JPG";
// import gallery16 from "../../images/logo/gallery/gallery16.JPG";
import gallery17 from "../../images/logo/gallery/gallery17.JPG";
import gallery18 from "../../images/logo/gallery/gallery18.JPG";
import gallery19 from "../../images/logo/gallery/gallery19.JPG";
import gallery20 from "../../images/logo/gallery/gallery20.JPG";
import gallery21 from "../../images/logo/gallery/gallery21.JPG";
// import gallery22 from "../../images/logo/gallery/gallery22.HEIC";
import gallery23 from "../../images/logo/gallery/gallery23.PNG";
import gallery24 from "../../images/logo/gallery/gallery24.JPG";
import gallery25 from "../../images/logo/gallery/gallery25.JPG";
import gallery26 from "../../images/logo/gallery/gallery26.PNG";
import gallery27 from "../../images/logo/gallery/gallery27.PNG";
import gallery28 from "../../images/logo/gallery/gallery28.JPG";
import gallery29 from "../../images/logo/gallery/gallery29.PNG";
import gallery30 from "../../images/logo/gallery/gallery30.PNG";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Fade, Zoom } from "react-awesome-reveal";

const Portfolios = [
  {
    Pimg: gallery1,
  },
  {
    Pimg: gallery3,
  },
  {
    Pimg: gallery2,
  },
  {
    Pimg: gallery4,
  },
  {
    Pimg: gallery5,
  },
  {
    Pimg: gallery6,
  },
  {
    Pimg: gallery7,
  },
  {
    Pimg: gallery8,
  },
  {
    Pimg: gallery9,
  },
  {
    Pimg: gallery10,
  },
  {
    Pimg: gallery11,
  },
  {
    Pimg: gallery12,
  },
  {
    Pimg: gallery13,
  },
  {
    Pimg: gallery14,
  },
  {
    Pimg: gallery15,
  },
  // {
  //   Pimg: gallery16,
  // },
  {
    Pimg: gallery17,
  },
  {
    Pimg: gallery18,
  },
  {
    Pimg: gallery19,
  },
  {
    Pimg: gallery20,
  },
  {
    Pimg: gallery21,
  },
  // {
  //   Pimg: gallery22,
  // },
  {
    Pimg: gallery23,
  },
  {
    Pimg: gallery24,
  },
  {
    Pimg: gallery25,
  },
  {
    Pimg: gallery26,
  },
  {
    Pimg: gallery27,
  },
  {
    Pimg: gallery28,
  },
  {
    Pimg: gallery29,
  },
  {
    Pimg: gallery30,
  },
];

const PortfolioSectionS2 = (props) => {
  const [open, setOpen] = React.useState(false);
  const [slider, setSlider] = React.useState(null);

  return (
    <section
      className={`wpo-portfolio-section-s2 section-padding ${props.prClass}`}
      id="gallery"
    >
      <div className="container-fluid">
        <div className="sortable-gallery">
          <div className="gallery-filters"></div>
          <div className="col col-lg-10 offset-lg-1">
            {" "}
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-grids gallery-container  clearfix">
                  <ResponsiveMasonry
                    // columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                  >
                    <Masonry columnsCount={4} gutter="50" >
                      {Portfolios.map((image, i) => (
                        <div className="grid"  key={i}>
                          {console.log(image)}

                          <Zoom direction="up">
                            <div
                              className="img-holder m-1 h-100"
                              onClick={() => {
                                setOpen(true);
                                setSlider(i);
                              }}
                            >
                              <img
                                src={image.Pimg}
                                alt=""
                                className="img img-fluid img-responsive object-fit-cover"
                              />
                              <div className="hover-content">
                                <i className="ti-plus"></i>
                              </div>
                            </div>
                          </Zoom>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          { src: Portfolios[slider]?.Pimg },
          { src: gallery1 },
          { src: gallery3 },
          { src: gallery2 },
          { src: gallery4 },
          { src: gallery5 },
          { src: gallery6 },
          { src: gallery7 },
          { src: gallery8 },
          { src: gallery9 },
          { src: gallery10 },
          { src: gallery11 },
          { src: gallery12 },
          { src: gallery13 },
          { src: gallery14 },
          { src: gallery15 },
          // { src: gallery16 },
          { src: gallery17 },
          { src: gallery18 },
          { src: gallery19 },
          { src: gallery20 },
          { src: gallery21 },
          // { src: gallery22 },
          { src: gallery23 },
          { src: gallery24 },
          { src: gallery25 },
          { src: gallery26 },
          { src: gallery27 },
          { src: gallery28 },
          { src: gallery29 },
          { src: gallery30 },
        ]}
      />
    </section>
  );
};

export default PortfolioSectionS2;
