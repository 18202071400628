import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Testimonial2 from "../../components/Testimonial2/Testimonial2";
import Navbar from "../../components/Navbar/Navbar";
import { Fragment } from "react";
import GalleryPageTitle from "../../components/pagetitle/GalleryPageTitle";

const TestMonials = () => {
  return (
    <Fragment>
      <Navbar />

      {/* <GalleryPageTitle pageTitle={"Testmonials"} pagesub={"Testmonials"} /> */}
      <Testimonial2 tClass={"wpo-testimonial-section-s3  pb-120 pb-1"} />
      {/* <EventSection /> */}
      {/* <BlogSection /> */}
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};

export default TestMonials;
