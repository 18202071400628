import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import About from "../../components/about/about";

import AboutPageTitle from "../../components/pagetitle/AboutPageTitle";

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <AboutPageTitle pageTitle={"About Us"} pagesub={"About"} /> */}
      <About />
      {/* <FunFact fClass={'section-padding pt-0'}/> */}
      {/* <ServiceSectionS2 /> */}
      {/* <Testimonial2 tClass={"wpo-testimonial-section-s3  pb-120 pb-1"} /> */}
      {/* <PortfolioSectionS3 /> */}
      {/* <TeamSection /> */}
      {/* <PricingSection pClass={"pt-0"} />
      <PartnerSection pClass={"section-padding pt-0"} /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default AboutPage;
