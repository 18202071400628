import React, { useState } from "react";

import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ShowCards from "../../MainMenu/showCards";
import Badam_Lassi from "../../../images/logo/Badam_Lassi.jpeg";
import Sugarcane from "../../../images/logo/Sugarcane.jpeg";
import Jigarthanda from "../../../images/logo/Jigarthanda.jpeg";
import drigonfruit from "../../../images/logo/drigonfruit.jpeg";
import waterwelon from "../../../images/logo/waterwelon.jpeg";
import pinapple from "../../../images/logo/pinapple.jpeg";
import grape from "../../../images/logo/grape.jpeg";
import muskmelon from "../../../images/logo/muskmelon.jpeg";

import ShowCards from "../../MainMenu/showCards";
import RSVPFrom from "../../RSVPFrom/RSVPFrom";

const WelcomeDrinkIndex = (props) => {
  const accordionId = "accordionExample";

  const [data, setData] = useState([
    { name: "Musk Melon", img: muskmelon, active: false },
    { name: "Grape", img: grape, active: false },
    { name: "Pineapple", img: pinapple, active: false },
    { name: "Water Melon", img: waterwelon, active: false },
    { name: "Dragon Fruit", img: drigonfruit, active: false },
    { name: "Jigarthanda", img: Jigarthanda, active: false },
    { name: "Sugarcane", img: Sugarcane, active: false },
    { name: "Badam Lassi", img: Badam_Lassi, active: false },
  ]);
  const [eveningSnacks, seteveningSnacks] = React.useState(
    JSON.parse(localStorage.getItem("evening"))
  );
  const [noonLunch, setNoonLunch] = React.useState(
    JSON.parse(localStorage.getItem("noon"))
  );
  console.log(noonLunch);
  const [menu, setMenu] = React.useState();

  function saveCheckboxValues(type) {
    const checkboxes = document.querySelectorAll(".noon");
    let checkedValues = [];

    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkedValues.push(checkbox.value);
      }
    });
    setNoonLunch(checkedValues);

    // if (checkboxes == true) {
    localStorage.setItem(type, JSON.stringify(noonLunch));

    // }
  }

  function eveningCheckboxValues(type) {
    const checkboxes1 = document.querySelectorAll(".evening");
    let checkedValues1 = [];

    checkboxes1.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkedValues1.push(checkbox.value);
      }
    });
    seteveningSnacks(checkedValues1);

    // if (checkboxes == true) {
    localStorage.setItem(type, JSON.stringify(eveningSnacks));

    // }
  }

  const handleSubmit = (type) => {
    saveCheckboxValues(type);
  };
  const handleEvening = (type) => {
    eveningCheckboxValues(type);
  };

  return (
    <section className="wpo-contact-pg-section ">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              {" "}
              <div className="row">
                <div class="accordion" id="accordionExample">
                  <ShowCards data={data} setData={setData} id={accordionId} />
                </div>
              </div>
            </div>
            {/* <div className="wpo-contact-title">
                          <h2>Have Any Question?</h2>
                          <p>It is a long established fact that a reader will be distracted
                              content of a page when looking.</p>
                      </div> */}
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col ">
          <div
            class="modal"
            id="myModal"
            // style={{ marginTop: "5rem" }}
          >
            <div class="modal-dialog modal-dialog-scrollable">
              <div class="modal-content ">
                <div class="modal-header">
                  <h4 class="modal-title">Please fill up the form</h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div class="modal-body">
                  <RSVPFrom />
                </div>

                <div class="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-contact-pg-section ">
        <div
          class="modal"
          id="myModal1"
          // style={{ marginTop: "5rem" }}
        >
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content ">
              <div class="modal-header">
                <h4 class="modal-title">Please fill up the form</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div class="modal-body">
                <RSVPFrom />
              </div>

              <div class="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeDrinkIndex;
