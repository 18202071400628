import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from "../../images/logo/wedding.jpg";
import ts2 from "../../images/logo/gallery/gallery4.JPG";
import ts3 from "../../images/logo/gallery/gallery17.JPG";
import thumb1 from "../../images/testimonial/thumb-1.jpg";
import thumb2 from "../../images/testimonial/thumb-2.jpg";
import thumb3 from "../../images/testimonial/thumb-3.jpg";

import shape1 from "../../images/testimonial/svg-1.svg";
import shape2 from "../../images/testimonial/svg-2.svg";
import shape3 from "../../images/testimonial/svg-3.svg";
import shape4 from "../../images/testimonial/svg-4.svg";
import { Zoom } from "react-awesome-reveal";

const testimonial = [
  {
    id: "01",
    tImg: ts1,
    thumb: ts1,
    Des: "Quality, quantity and taste - all 5 star! We had booked GTS catering for my wedding! The best decision we ever made. Our guests simply enjoyed the wedding food and we still receive compliments about them. Every single dish was extremely delicious! GTS uncle and their sons took utmost care throughout the event to make sure we were comfortable ! We had the most unforgettable dining experience at our wedding ! Thank you so much for making our day memorable GTS family !  :) If you are looking for the best food catering for your weddings, family Services, I would highly recommend them! This is the best place you can ever come across ! ",
    Title: "Aarthi Bakthavathsalam",
    Date: "23.05.2023",
  },
  {
    id: "01",
    tImg: ts2,
    thumb: ts2,
    Des: "The best caterer we have ever tasted. We booked GTS for both marriages of our family which is very close dates. They have given the best & different menu for both the function and the taste was very unique & so good. We just admire the way they treated every person in our marriage function. We are so much glad and I personally suggest GTS to everyone for their upcoming family Services. Thank You!",
    Title: "Sathya Saravanan",
    Date: "08.12.2023",
  },
  {
    id: "01",
    tImg: ts3,
    thumb: ts3,
    Des: "Really awesome foods. Different items Different tastes. The taste never forgets. Served with love and affection. Homely Food. We can say 'SATHVEGA' food. Serving people's are so nice. Try taste once......",
    Title: "Baktha Vathsalam",
    Date: "12.08.2023",
  },
  {
    id: "01",
    tImg: ts3,
    thumb: ts3,
    Des: "The food was very tasty , each and everyone complimented the food . All the dishes were delicious , We are veryyy much satisfied for having GTS for our function and we will never fail to book GTS for our upcoming Services",
    Title: "Anbu mani",
    Date: "12.08.2023",
  },
  {
    id: "01",
    tImg: ts3,
    thumb: ts3,
    Des: "I have been enjoying their food for past 15 years ..😍 The best cooking class ever 🤩 Absolutely recommended to all food lovers",
    Title: "MJK Guru",
    Date: "12.08.2023",
  },
  {
    id: "01",
    tImg: ts3,
    thumb: ts3,
    Des: "GTS CATERING made the experience so great for all my guests⭐️⭐️⭐️⭐️⭐️They gave us an amazing experience 💥The food was best I've ever had the service was incredible and very professional💯&Excellent hospitality❤️",
    Title: "Logesh",
    Date: "12.08.2023",
  },
];

const Testimonial2 = (props) => {
  const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <section id="testmonial" className={`wpo-testimonial-section-s2 ${props.tClass}`}>
      <div className="container">
        <Zoom>
          {" "}
          <div className="wpo-testimonial-wrap">
            <div className="row">
              <div className="col col-lg-12">
                <div className="wpo-testimonial-items">
                  <div className="wpo-section-title">
                    {/* <span>Testimonials</span> */}
                    <h2>What Our Client Says</h2>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p> */}
                  </div>

                  <div className="wpo-testimonial-active">
                    <Slider {...settings}>
                      {testimonial.map((tesmnl, tsm) => (
                        <div className="wpo-testimonial-item" key={tsm}>
                          <div className="wpo-testimonial-text">
                            <div className="row wpo-testimonial-text-btm ">
                              {/* <div className="col col-lg-6  col ">
                                <div className="tsm-img">
                                  {" "}
                                  <img src={tesmnl.thumb} alt="" /> 
                                </div>
                              </div> */}
                              <div className="col col-lg-12">
                                {" "}
                                <p>"{tesmnl.Des}"</p>
                                <h3>{tesmnl.Title}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </section>
  );
};

export default Testimonial2;
