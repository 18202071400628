import React from "react";
import pImg1 from "../../images/logo/gallery/gallery1.JPG";
import pImg2 from "../../images/logo/gallery/gallery12.JPG";
import pImg3 from "../../images/logo/gallery/gallery23.PNG";
import pImg4 from "../../images/logo/gallery/gallery8.JPG";
import pImg5 from "../../images/logo/gallery/gallery5.JPG";
import pImg6 from "../../images/logo/gallery/gallery20.JPG";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";

const settings = {
  dots: false,
  arrows: true,
  speed: 1500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Portfolios = [
  {
    Pimg: pImg1,
  },
  {
    Pimg: pImg2,
  },
  {
    Pimg: pImg3,
  },
  {
    Pimg: pImg4,
  },
  {
    Pimg: pImg5,
  },
  {
    Pimg: pImg6,
  },
];

const PortfolioSectionS3 = (props) => {
  const [open, setOpen] = React.useState(false);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className={`wpo-portfolio-section section-padding mt-5 ${props.ptClass}`}
      id="gallery"
    >
      <h2 className="hidden">some</h2>
      <div className="container">
        <SectionTitle
          className="text-light"
          // subTitle={"Our Story"}
          MainTitle={"Gallery"}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-grids gallery-container clearfix portfolio-slide">
              <Slider {...settings}>
                {Portfolios.map((image, i) => (
                  <div className="grid" key={i}>
                    <div className="img-holder" onClick={() => setOpen(true)}>
                      <img
                        src={image.Pimg}
                        alt=""
                        className="img img-responsive"
                      />
                      <div className="hover-content">
                        <i className="ti-plus"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <Zoom>
                {" "}
                <div className="text-center ">
                  <NavLink
                    onClick={ClickHandler}
                    to="/gallery"
                    className="view-cart-btn"
                  >
                    See More
                  </NavLink>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          { src: pImg1 },
          { src: pImg2 },
          { src: pImg3 },
          { src: pImg4 },
          { src: pImg5 },
          { src: pImg6 },
        ]}
      />
    </section>
  );
};

export default PortfolioSectionS3;
