import React, { Fragment } from "react";
import { connect } from "react-redux";
import PageTitle from "../../../components/pagetitle/PageTitle";
import Scrollbar from "../../../components/scrollbar/scrollbar";
import { addToCart, addToWishList } from "../../../store/actions/action";
import ShopProduct from "../../../components/ShopProduct/dinner";
import api from "../../../api";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import Premium from "../../../components/ShopProduct/dinner/Premium";
import Luxury from "../../../components/ShopProduct/dinner/Luxury";

const Dinner = ({ addToCart, addToWishList }) => {
  const productsArray = api();

  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
  };

  const addToWishListProduct = (product) => {
    addToWishList(product);
  };
  const [dinner, setDinner] = React.useState("standard");

  const products = productsArray;

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <PageTitle pageTitle={"Dinner"} pagesub={"Dinner"} /> */}
      <nav className="toggleNav">
        <ul className="d-flex justify-content-center fs-2 ">
          <li
            className={`p-4 cursor- ${
              dinner == "standard" ? "text-warning" : ""
            }`}
            style={{ cursor: "pointer" }}
            id="standard"
            onClick={() => setDinner("standard")}
          >
            Standard Dinner
          </li>
          <li className="p-4 cursor-pointer">
            <i className="	fa fa-angle-double-right"></i>
          </li>
          <li
            className={`p-4 cursor-pointer ${
              dinner == "premium" ? "text-warning" : ""
            }`}
            style={{ cursor: "pointer" }}
            id="premium"
            onClick={() => setDinner("premium")}
          >
            Premium Dinner
          </li>
          <li className="p-4 cursor-pointer">
            <i className="	fa fa-angle-double-right"></i>
          </li>
          <li
            className={`p-4 cursor-pointer ${
              dinner == "luxury" ? "text-warning" : ""
            }`}
            style={{ cursor: "pointer" }}
            id="luxury"
            onClick={() => setDinner("luxury")}
          >
            Luxury Dinner{" "}
          </li>
        </ul>
      </nav>
      <section className="wpo-shop-page section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {dinner == "standard" && <ShopProduct />}
              {dinner == "premium" && <Premium />}
              {dinner == "luxury" && <Luxury />}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default connect(null, { addToCart, addToWishList })(Dinner);
