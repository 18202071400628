import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const Contactpage = () => {
  const [value, setValue] = React.useState("");
  const onClick = React.useCallback(({ target: { innerText } }) => {
    setValue(innerText);
  }, []);

  const onCopy = React.useCallback(() => {
    toast("Copied to clipboard");
  }, []);
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container-fluid">
        <div className="col col-lg-10 offset-lg-1">
          <div className="row">
            {" "}
            <div className="wpo-contact-title">
              <h2>Contact Information</h2>
              <p>
                Our hearts are connected with your's by food but we are still a
                phone call away! Get to us via phone, e-mail or telefax and we
                will be happy to assist you.
              </p>
            </div>
          </div>
          <div className="office-info">
            <div className="office-info">
              <div className="row">
                <div className="col col-lg-6 col-12">
                  {" "}
                  <Zoom>
                    {" "}
                    <div className="col ">
                      <div className="office-info-item">
                        <div className="office-info-icon">
                          <div className="d-flex align-items-center icon gap-3">
                            <div className="d-flex align-items-center ">
                              {" "}
                              <i className="fi flaticon-maps-and-flags"></i>{" "}
                              <h3>Address:</h3>
                            </div>{" "}
                            <div className="office-info-text  text-wrap">
                              <p>26, Perumal West Street, Nagapattinam,</p>
                              <p>India, Tamil Nadu</p>
                              {/* <p>helloyou@gmail.com</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col ">
                      <div className="office-info-item">
                        <div className="office-info-icon">
                          <div className="d-flex align-items-center icon gap-3">
                            <div className="d-flex align-items-center ">
                              {" "}
                              <i className="fi flaticon-email"></i>{" "}
                              <h3>Email Us:</h3>
                            </div>{" "}
                            <div className="office-info-text  text-wrap">
                              <p>
                                <a
                                  href="mailto:gtscatering1993@gmail.com"
                                  style={{ color: "#5c5c5c" }}
                                >
                                  gtscatering1993@gmail.com{" "}
                                </a>
                              </p>
                              {/* <p>helloyou@gmail.com</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col ">
                      <div className="office-info-item">
                        <div className="office-info-icon">
                          <div className="d-flex align-items-center icon gap-3">
                            {" "}
                            <div className="d-flex align-items-center ">
                              <i className="fi flaticon-phone-call"></i>{" "}
                              <h3>Call Now:</h3>{" "}
                            </div>
                            <div className=" d-flex ">
                              {/* <div className="desktop-view d-none d-md-block">
                                <CopyToClipboard
                                  onCopy={onCopy}
                                  // options={{message: 'Whoa!'}}
                                  text={value}
                                  style={{ cursor: "pointer", height: "20px" }}
                                >
                                  <div onClick={onClick}>94431 90255</div>
                                </CopyToClipboard>
                                <CopyToClipboard
                                  onCopy={onCopy}
                                  // options={{message: 'Whoa!'}}
                                  text={value}
                                  className="mt-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div onClick={onClick}>98429 90255</div>
                                </CopyToClipboard>
                              </div> */}
                              <div className="office-info-text d-flex flex-column align-items-center ">
                                <p>
                                  <a
                                    href="tel:+919443190255"
                                    style={{
                                      cursor: "pointer",
                                      height: "20px",
                                      color: "#5c5c5c",
                                    }}
                                  >
                                    94431 90255
                                  </a>
                                </p>
                                <p>
                                  <a
                                    href="tel:+919842990255"
                                    style={{
                                      cursor: "pointer",
                                      height: "20px",
                                      color: "#5c5c5c",
                                    }}
                                  >
                                   98429 90255
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                </div>{" "}
                <div className="col col-lg-6 col-12">
                  {" "}
                  <Fade direction="up">
                    <div className="">
                      <iframe
                        className="w-100"
                        height="400"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6634305417974!2d79.8389577!3d10.760401399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a556c7b1052f26d%3A0x12910a78216bde54!2sGTS%20CATERING!5e0!3m2!1sen!2sin!4v1710580927294!5m2!1sen!2sin"
                        // style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>{" "}
                  </Fade>
                </div>
                {/* <div className="col col-lg-6 col-12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6664095908263!2d79.83626877408724!3d10.760172259495281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a556c7b1b26f2bd%3A0x2cc69372ee04caad!2s26%2C%20Perumal%20W%20St%2C%20Melakottaivasal%2C%20Keraikoai%20Theru%2C%20Nagapattinam%2C%20Tamil%20Nadu%20611001!5e0!3m2!1sen!2sin!4v1710561947545!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div> */}
              </div>
            </div>
          </div>
          {/* <Slide direction="up"> */}
          <div id="contact" className="wpo-contact-form-area">
            <ContactForm />
          </div>{" "}
          {/* </Slide> */}
        </div>
      </div>
      {/* <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"></iframe>
                </div>
            </section> */}
    </section>
  );
};

export default Contactpage;
