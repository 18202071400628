import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero2 from "../../components/hero2/hero2";
import CoupleSection2 from "../../components/CoupleSection2/CoupleSection2";
import StorySection2 from "../../components/StorySection2/StorySection2";

import RsvpSection from "../../components/RsvpSection/RsvpSection";
import CountUp from "react-countup";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import PortfolioSectionS3 from "../../components/PortfolioSectionS3/PortfolioSectionS3";
import Testimonial2 from "../../components/Testimonial2/Testimonial2";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero2 />
      <CoupleSection2 />
<div className="mr-md-50" style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
<div className="border-count"  style={{padding:"0 80px",display:"flex",alignItems:"center",flexDirection:"column"}} >
  <p style={{textAlign:"center",color:"#511b87",fontSize:"28px",fontWeight:600}} className="text-align-center">
  <CountUp duration={10} className="counter" end={31} />
  </p>
  <p className="text-align-center" style={{fontSize:"28px", color:"#511b87"}}>No of Years</p>
</div>
<div className="border-count" style={{padding:"0 80px",display:"flex",alignItems:"center",flexDirection:"column"}} >
  <p style={{textAlign:"center",color:"#511b87",fontSize:"28px",fontWeight:600}} className="text-align-center">
    <CountUp duration={10} className="counter" end={250} />+
  </p>
  <p className="text-align-center" style={{fontSize:"28px", color:"#511b87"}}>Team Members</p>
</div>
<div className="border-count" style={{padding:"0 80px",display:"flex",alignItems:"center",flexDirection:"column"}} >
  <p style={{textAlign:"center",color:"#511b87",fontSize:"28px",fontWeight:600}} className="text-align-center">
    <CountUp duration={10} className="counter" end={2500} />+
  </p>
  <p className="text-align-center" style={{fontSize:"28px", color:"#511b87"}}>No of Events</p>
</div>
<div style={{padding:"0 80px",display:"flex",alignItems:"center",flexDirection:"column"}} >
  <p style={{textAlign:"center",color:"#511b87",fontSize:"28px",fontWeight:600}} className="text-align-center">
    <CountUp duration={10} className="counter" end={100000} />+
  </p>
  <p className="text-align-center" style={{fontSize:"28px", color:"#511b87"}}>Satisfied Guests</p>
</div>

</div>
<div className="position-relative " style={{marginTop:"40px"}}>
<div style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
  <h1 className="hed text-center" >Your Happiness Is Our <span>Take Away</span></h1> 
  
  
</div>
<div style={{width:"100%",position:"relative"}} ><div className="tm"></div></div>
</div>
<div className="md-mr" style={{display:"flex",justifyContent:"space-around",flexWrap:"wrap"}}>
  <div style={{width:"220px",borderRadius:"16px",padding:"50px",height:"220px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",boxShadow:"0 1rem 3rem rgba(0, 0, 0, 0.175)"}}>
    <img src="https://www.parvathicaterings.com/img/icon1.webp" className="hovImg"/>
    <h3 class="mt-2">Taste</h3>
  </div>
  <div style={{width:"220px",borderRadius:"16px",padding:"50px",height:"220px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",boxShadow:"0 1rem 3rem rgba(0, 0, 0, 0.175)"}}>
    <img src="https://www.parvathicaterings.com/img/icon2.webp" className="hovImg"/>
    <h3 class="mt-2">Quality</h3>
  </div>
  <div style={{width:"220px",borderRadius:"16px",padding:"50px",height:"220px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",boxShadow:"0 1rem 3rem rgba(0, 0, 0, 0.175)"}}>
    <img src="https://www.parvathicaterings.com/img/icon3.webp" className="hovImg"/>
    <h3 style={{whiteSpace:"nowrap"}} class="mt-2">Service Excellence</h3>
  </div>
  <div style={{width:"220px",borderRadius:"16px",padding:"50px",height:"220px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",boxShadow:"0 1rem 3rem rgba(0, 0, 0, 0.175)"}}>
    <img src="https://www.parvathicaterings.com/img/icon4.webp" className="hovImg"/>
    <h3 class="mt-2">Value</h3>
  </div>
</div>
      <PortfolioSectionS3 />
      <StorySection2 />
      <RsvpSection />
      <Testimonial2 tClass={"wpo-testimonial-section-s3  pb-120 pb-1"} />
      {/* <EventSection /> */}
      {/* <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
