import React, { useState } from "react";
import ShowCards from "../../MainMenu/showCards";
import PaneerJamun from "../../../images/logo/Breakfast/paneer_jamun.jpg";
import kalkanduPongal from "../../../images/logo/Breakfast/kalkandu_pongal.jpg";
import KismissRaitha from "../../../images/logo/Breakfast/kismis_raitha.jpg";
import DragonFruitJam from "../../../images/logo/Breakfast/dragon_fruit_jam.jpg";
import CarrotCapsicumPattaniPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import BrocolliMasalaKari from "../../../images/logo/Breakfast/brocolli_masalakari.jpg";
import MushroomPallipalayam from "../../../images/logo/Breakfast/mushroom_pallipalayam.jpg";
import VendaikaiNilakadalaiPulimandi from "../../../images/logo/Breakfast/vendaikai_nilakadalai_pulimandi.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import CurdVadai from "../../../images/logo/Breakfast/curd_vadai.jpg";
import RambuttanPickle from "../../../images/logo/Breakfast/rambuttan_pickle.jpg";
import ChappathiDalMakhani from "../../../images/logo/Breakfast/chappathi_dal_makhani.jpg";
import karupeypillaiRice from "../../../images/logo/Breakfast/karupeypillai_rice.jpg";
import VadakaThuvayal from "../../../images/logo/Breakfast/vadaka_thuvayal.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import Sambar from "../../../images/logo/Breakfast/sambhar.webp";
import VendhayaThokku from "../../../images/logo/Breakfast/endhaya_thokku.jpg";
import MoorKulambu from "../../../images/logo/Breakfast/moor_kulambu.jpg";
import NellikaiRasam from "../../../images/logo/Breakfast/nellikai_rasam.jpg";
import ElaneerPayasam from "../../../images/logo/Breakfast/elaneer_payasam.jpg";
import PotCurd from "../../../images/logo/Breakfast/pot_curd.jpg";
import Ghee from "../../../images/logo/Breakfast/ghee.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import dryjamun from "../../../images/logo/Breakfast/DryJamun.jpg";

const Luxury = () => {
  const [data, setData] = useState([
    { name: "Dry Jamun", active: false, img: dryjamun }, //noon-lunch
    { name: "Sonali Roll /Paneer Jamun", active: false, img: PaneerJamun },
    { name: "Kalkandu Pongal", active: false, img: kalkanduPongal },
    {
      name: "Kismis Raitha/ Avacado Raitha",
      active: false,
      img: KismissRaitha,
    },
    {
      name: "Dragon Fruit Jam",
      active: false,
      img: DragonFruitJam,
    },
    {
      name: "Carrot Capsicum Pattani Poriyal",
      active: false,
      img: CarrotCapsicumPattaniPoriyal,
    },
    {
      name: "Brocolli MasalaKari / Mini Cabbage MasalaKari",
      active: false,
      img: BrocolliMasalaKari,
    },
    {
      name: "Mushroom Pallipalayam",
      active: false,
      img: MushroomPallipalayam,
    },
    {
      name: "Vendaikai Nilakadalai Pulimandi",
      active: false,
      img: VendaikaiNilakadalaiPulimandi,
    },
    { name: "Malabar Aviyal", active: false, img: MalabarAviyal },
    {
      name: "Valai Poo Chips",
      active: false,
      img: ValaiPooChips,
    },
    {
      name: "Curd Vadai / Curd Mini Bonda",
      active: false,
      img: CurdVadai,
    },
    {
      name: "Rambuttan Pickle / IlanthaVathal Pickle",
      active: false,
      img: RambuttanPickle,
    },
    {
      name: "Chappathi - Dal Makhani / Paneer Mutter Gravy",
      active: false,
      img: ChappathiDalMakhani,
    },
    {
      name: "Karupeypillai Rice / Jeera Rice / Mint Rice",
      active: false,
      img: karupeypillaiRice,
    },
    { name: "Vadaka Thuvayal", active: false, img: VadakaThuvayal },
    { name: "Rice", active: false, img: Rice },
    { name: "Sambar", active: false, img: Sambar },
    {
      name: "Vendhaya Thokku/ Inji Thokku / BrinjalThokku",
      active: false,
      img: VendhayaThokku,
    },
    {
      name: "Moor Kulambu/ Parupu UrundaiKulambu",
      active: false,
      img: MoorKulambu,
    },
    {
      name: "Nellikai Rasam / MosambiRasa",
      active: false,
      img: NellikaiRasam,
    },
    {
      name: "Elaneer Payasam / Nongu Payasam ( Pot )",
      active: false,
      img: ElaneerPayasam,
    },
    { name: "Pot Curd", active: false, img: PotCurd },
    { name: "Porupu Podi , Ghee", active: false, img: Ghee },
    { name: "Appalam", active: false, img: Appalam },
  ]);

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };
  const [number, setCount] = useState(8);
  const [buttonActive, setButtonState] = useState(false);

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default Luxury;
