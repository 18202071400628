import React from "react";
import { Zoom } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/logo/wedding.png";
import sImg3 from "../../images/logo/gallery1.png";
import sImg2 from "../../images/logo/birthday2.png";

import shape1 from "../../images/story/shape.png";
import shape2 from "../../images/story/shape2.png";
import shape3 from "../../images/story/shape3.png";

import bshape1 from "../../images/story/flower-shape1.svg";
import bshape2 from "../../images/story/flower-shape2.svg";
import bshape3 from "../../images/story/flower-shape3.svg";
import bshape4 from "../../images/story/flower-shape4.svg";
import { NavLink } from "react-router-dom";

const Storys = [
  {
    sImg: sImg1,
    shape: shape1,
    title: "Wedding",
    date: "15 June 2014",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor vitae nisl. Erat fringilla pellentesque amet tempus. Commodo mi vitae, sagittis blandit.",
  },

  {
    sImg: sImg2,
    shape: shape3,
    title: "Birthdays",
    date: "12 Dec 2019",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor vitae nisl. Erat fringilla pellentesque amet tempus. Commodo mi vitae, sagittis blandit.",
    // order1: "order-lg-2 order-1",
    // order2: "order-lg-1 order-2",
  },
  {
    sImg: sImg3,
    shape: shape3,
    title: "Social",
    date: "16 Jan 2023",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor vitae nisl. Erat fringilla pellentesque amet tempus. Commodo mi vitae, sagittis blandit.",
  },
];

const StorySection2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle
          className="text-light"
          // subTitle={"Our Story"}
          MainTitle={"Spice Up Your Occasions..."}
        />
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="wpo-story-img-wrap">
                <Zoom direction="up" duration="1000" triggerOnce="true">
                  <div className="wpo-story-img" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <img src={story.sImg} style={{height:"400px"}} alt="" />
                  </div>
                </Zoom>
                {/* <div className="clip-shape">
                  <svg
                    viewBox="0 0 382 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                  </svg>
                </div> */}
                {/* <div className="wpo-img-shape">
                  <img src={story.shape} alt="" />
                </div> */}
              </div>
              <div className="wpo-story-content">
                <div className="wpo-story-content-inner">
                  {/* <span>{story.date}</span> */}
                  <h2 className="text-light">{story.title}</h2>
                  {/* <p>{story.description}</p> */}
                </div>
              </div>
            </div>
          ))}
          <Zoom direction="up" duration="1000" triggerOnce="true">
            {" "}
            <div className=" d-flex flex-row-reverse">
              <NavLink
                onClick={ClickHandler}
                to="/event"
                className="theme-btn-s2"
              >
                Read More..
              </NavLink>
            </div>
          </Zoom>
        </div>
      </div>
      {/* <div className="flower-shape-1">
        <img src={bshape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={bshape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={bshape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={bshape4} alt="" />
      </div> */}
    </section>
  );
};

export default StorySection2;
