import React from "react";
import SectionTitle from "../SectionTitle";
import abimg from "../../images/logo/aboutImg1.jpg";
import abimg2 from "../../images/logo/gallery25.jpg";
// import sign from "../../images/about/signature.png";
import { Fade, Zoom } from "react-awesome-reveal";
import CoupleSection2 from "../CoupleSection2/CoupleSection2";

const About = (props) => {
  return (
    <section className="wpo-about-section " style={{ padding: "50px 0" }}>
      <div className="">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10 col-12">
            <SectionTitle
              subTitle={"Who We Are?"}
              // MainTitle={"We are The World Best Wedding Agency"}
            />
          </div>{" "}
        </div>
        {/* <CoupleSection2/> */}

        <section className="" style={{ padding: "50px 0" }} id="couple">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div
              className="couple-img-wrap"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                // marginLeft: 80,

                justifyContent: "space-evenly",
              }}
            >
              <Zoom
                direction="up"
                duration="1200"
                triggerOnce="true"
                className=" home-couple-text"
              >
                <div style={{ maxWidth: 500 }} className="couple-text ">
                  <h2 className="" style={{ fontWeight: 500 }}>
                    Trusted By 200 + Satisfied Clients
                  </h2>
                  <p className="" style={{ textAlign: "justify" }}>
                    Just Tell us your choice of food menu and make your tongue
                    feel the taste. We has come a long way since its
                    establishment in the year 1993. We are the prominent service
                    providers in the catering industry.
                    <br />
                    Our menu style varies depends on the guest , function theme
                    and preferences.
                    <br />
                    We are the best and highly demanded vegetarian catering
                    service in Nagapattinam.
                    <br />
                    Our food is inspired through the use of carefully selected
                    ingredients.
                  </p>
                </div>
              </Zoom>
              <Fade direction="up" duration="1200" triggerOnce="true">
                <div>
                  <img
                    src={
                      "https://zerothindex.co.in/gts/assets/images/main/aboutprof.jpg"
                    }
                    style={{
                      borderRadius: "10px",
                      width: "400px",
                      // minWidth: "400px",
                    }}
                    alt=""
                  />
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="  " style={{ padding: "50px 0" }} id="couple">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div
              className="couple-img-wrap"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                // marginLeft: 80,
                justifyContent: "space-evenly",
              }}
            >
              <Fade direction="up" duration="1200" triggerOnce="true">
                <div>
                  <img
                    src={
                      "https://zerothindex.co.in/gts/assets/images/main/home1.jpg"
                    }
                    style={{
                      borderRadius: "10px",
                      width: "400px",
                      marginTop: "15px",
                      // minWidth: "400px",
                    }}
                    alt=""
                  />
                </div>
              </Fade>
              <Zoom
                direction="up"
                duration="1200"
                triggerOnce="true"
                className=" home-couple-text"
              >
                <div
                  style={{ maxWidth: 500, marginTop: "15px" }}
                  className="couple-text "
                >
                  <h2 className="" style={{ fontWeight: 500 }}>
                    GTS Catering Service is our unwavering
                  </h2>
                  <p className="" style={{ textAlign: "justify" }}>
                    At the heart of GTS Catering Service is our unwavering
                    dedication to quality, creativity, and customer
                    satisfaction. We believe that every event is unique, and
                    therefore, deserves a bespoke culinary experience tailored
                    to its specific needs and preferences. Whether it's an
                    intimate gathering, a corporate function, or a grand
                    celebration, we approach each event with meticulous
                    attention to detail and a commitment to exceeding
                    expectations.
                  </p>
                </div>
              </Zoom>
            </div>
          </div>
        </section>
        {/* <div className="wpo-about-wrap">
          <div className="row align-items-center">
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12 col-12">
              <Fade direction="up" duration="1000" triggerOnce="true">
                <div className="wpo-about-text">
                  <p>
                    Just Tell us your choice of food menu and make your tongue
                    feel the taste. We has come a long way since its
                    establishment in the year 1993. We are the prominent service
                    providers in the catering industry.
                  </p>
                  <p>
                    Our menu style varies depends on the guest , function theme
                    and preferences.
                  </p>
                  <p>
                    We are the best and highly demanded vegetarian catering
                    service in Nagapattinam.
                  </p>
                  <p>
                    Our food is inspired through the use of carefully selected
                    ingredients.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                <div className="about-right-img">
                  <Zoom direction="up" duration="1000" triggerOnce="true">
                    <div className="about-right-img-inner">
                      <img src={abimg} alt="" />
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpo-about-wrap mt-5">
          <div className="row align-items-center">
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                <div className="about-right-img">
                  <Zoom direction="down" duration="1000" triggerOnce="true">
                    <div className="about-right-img-inner">
                      <img src={abimg2} alt="" />
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 mt-5 aboutPadding">
              <Fade direction="up" duration="1000" triggerOnce="true">
                <div className="wpo-about-text">
                  <p>
                    At the heart of GTS Catering Service is our unwavering
                    dedication to quality, creativity, and customer
                    satisfaction. We believe that every event is unique, and
                    therefore, deserves a bespoke culinary experience tailored
                    to its specific needs and preferences. Whether it's an
                    intimate gathering, a corporate function, or a grand
                    celebration, we approach each event with meticulous
                    attention to detail and a commitment to exceeding
                    expectations.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default About;
