import React, { useState } from "react";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import Raitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import FruitJam from "../../../images/logo/Breakfast/fruit_jam.jpg";
import CarrotCapsicumPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import SenaiChops from "../../../images/logo/Breakfast/senai_chops.jpg";
import CauliflowerMasalaKari from "../../../images/logo/Breakfast/cauliflower_masala_kari.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vada.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Briyani from "../../../images/logo/Breakfast/briyani.jpg";
import Thalcha from "../../../images/logo/Breakfast/thalcha.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import PineappleRasam from "../../../images/logo/Breakfast/pineapple_rasam.jpg";
import Curd from "../../../images/logo/Breakfast/curd.jpg";
import AdaiPrathamPayasam from "../../../images/logo/Breakfast/adai_pratham_payasam.jpg";
import Parupu from "../../../images/logo/Breakfast/parupu.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import KarupattiHalwa from "../../../images/logo/Breakfast/karupatti_halwa.jpg";
import ChottaBurfi from "../../../images/logo/Breakfast/chotta_burfi.jpg";
import RajmaCutlet from "../../../images/logo/Breakfast/rajma_cutlet.jpg";
import MushroomSaltPepper from "../../../images/logo/Breakfast/mushroom_salt&pepper.jpg";
import RomaliRoti from "../../../images/logo/Breakfast/romali_roti.jpg";
import KadaiPaneer from "../../../images/logo/Breakfast/kada_paneer.jpg";
import PuffsParotta from "../../../images/logo/Breakfast/puffs_parotta.jpg";
import PalakPannerGravy from "../../../images/logo/Breakfast/palak_pannerGravy.jpg";
import HyderabadDhumBriyani from "../../../images/logo/Breakfast/briyani.jpg";
import MirchiKaSalan from "../../../images/logo/Breakfast/mirchi_ka_salan.jpg";
import OnionPodiUthappam from "../../../images/logo/Breakfast/onion_podi_uthappam.jpg";
import KambuDosai from "../../../images/logo/Breakfast/kambu_dosai.jpg";
import KaruvapillaiPodiIdli from "../../../images/logo/Breakfast/karuvapillai_podi_idli.jpg";
import ButterPodiThattuIdli from "../../../images/logo/Breakfast/butter_podi_thattu_idli.jpg";
import BisibeleBath from "../../../images/logo/Breakfast/bisibele_bath.jpg";
import SenaiMilaguCurry from "../../../images/logo/Breakfast/senai_milagu_curry.jpg";
import WheatCurdRice from "../../../images/logo/Breakfast/wheat_curd_rice.jpg";
import BeetrootHalwa from "../../../images/logo/Breakfast/Beetroot_halwa.jpg";
import Rasamalai from "../../../images/logo/Breakfast/rasamalai.jpg";
import MushroomCornCutlet from "../../../images/logo/Breakfast/mushroom_masala.jpg";
import MaggiManchurianBalls from "../../../images/logo/Breakfast/maggi_manchurian_balls.jpg";
import MintMayo from "../../../images/logo/Breakfast/mint_mayo.jpg";
import CoinParotta from "../../../images/logo/Breakfast/coin_parotta.jpg";
import PepperMutterMasala from "../../../images/logo/Breakfast/pepper_mutterMasala.jpg";
import WheatParotta from "../../../images/logo/Breakfast/wheat_parotta.jpg";
import BrocolliPepperGravy from "../../../images/logo/Breakfast/brocolli_pepperGravy.jpg";
import KashmirPulav from "../../../images/logo/Breakfast/kashmir_pulav.jpg";
import PuraniRaitha from "../../../images/logo/Breakfast/purani_raitha.jpg";
import ButterPodiUthappam from "../../../images/logo/Breakfast/butter_podi_uthappam.jpg";
import BananaDosai from "../../../images/logo/Breakfast/banana_dosai.jpg";
import PaneerKothuIdiyappam from "../../../images/logo/Breakfast/paneer_kothu_idiyappam.jpg";
import CauliflowerFry from "../../../images/logo/Breakfast/cauliflower_fry.jpg";
import Idly from "../../../images/logo/Breakfast/Idli.jpg";
import MilletCurdRice from "../../../images/logo/Breakfast/millet_curd_rice.jpg";
import Cutney from "../../../images/logo/Breakfast/cutney.jpg";

const Premium = () => {
  const [sheer, setSheer] = React.useState(false);
  const [data, setData] = useState([
    {
      name: "Karupatti Halwa/Muscut Halwa",
      active: false,
      img: KarupattiHalwa,
    },
    {
      name: "Chotta Burfi / AngoorJamun",
      active: false,
      img: ChottaBurfi,
    },
    {
      name: "Rajma Cutlet/ Cheese Balls",
      active: false,
      img: RajmaCutlet,
    },
    {
      name: "Mushroom Salt & Pepper/ Honey Babycorn Fry",
      active: false,
      img: MushroomSaltPepper,
    },
    { name: "Romali Roti / Methi Roti", active: false, img: RomaliRoti },
    {
      name: "Kadai Paneer / Matar Malai Masala",
      active: false,
      img: KadaiPaneer,
    },
    { name: "Puffs Parotta/ Phulka", active: false, img: PuffsParotta },
    {
      name: "Palak PannerGravy / Aloo Capsicum Masala",
      active: false,
      img: PalakPannerGravy,
    },
    {
      name: "Hyderabad Dhum Briyani / Schezwan Noodles",
      active: false,
      img: HyderabadDhumBriyani,
    },
    { name: "Cucumber Onion Raitha / Sauce", active: false, img: Raitha },
    { name: "Mirchi Ka Salan", active: false, img: MirchiKaSalan },
    {
      name: "Onion Podi Uthappam / Corn MasalaDosai",
      active: false,
      img: OnionPodiUthappam,
    },
    { name: "Kambu Dosai/ Wheat Dosai", active: false, img: KambuDosai },
    {
      name: "Karuvapillai Podi Idli",
      active: false,
      img: KaruvapillaiPodiIdli,
    },
    {
      name: "Butter Podi Thattu Idli",
      active: false,
      img: ButterPodiThattuIdli,
    },
    { name: "3 Types Of Chutney", active: false, img: Cutney },
    { name: "Sambar", active: false, img: sambhar },
    {
      name: "Bisibele Bath / Vathakulambu Sadam",
      active: false,
      img: BisibeleBath,
    },
    {
      name: "Senai Milagu Curry / Appala poo",
      active: false,
      img: SenaiMilaguCurry,
    },
    {
      name: "Wheat Curd Rice / Bagala Bath",
      active: false,
      img: WheatCurdRice,
    },
    // { name: "Adai Pratham Payasam", active: false, img: AdaiPrathamPayasam },
    { name: "Beetroot Halwa/ Kasi Halwa", active: false, img: BeetrootHalwa },
    { name: "Rasamalai / Rasagulla", active: false, img: Rasamalai },
    {
      name: "Mushroom Corn Cutlet / Veg Roll",
      active: false,
      img: MushroomCornCutlet,
    },
    {
      name: "Maggi Manchurian Balls / Honey Chilli Potato",
      active: false,
      img: MaggiManchurianBalls,
    },
    { name: "Mint Mayo / Garlic Chilli Sauce", active: false, img: MintMayo },
    { name: "Coin Parotta/ Bun Parotta", active: false, img: CoinParotta },
    {
      name: "Pepper MutterMasala / Kadai Mushroom Masala",
      active: false,
      img: PepperMutterMasala,
    },
    {
      name: "Wheat Parotta/ Multigrain Chapathi",
      active: false,
      img: WheatParotta,
    },
    {
      name: "Brocolli PepperGravy / Malai Kofta Gravy",
      active: false,
      img: BrocolliPepperGravy,
    },
    { name: "Kashmir Pulav / FriedRice", active: false, img: KashmirPulav },
    {
      name: "Purani Raitha/ Gobi Manuchurian",
      active: false,
      img: PuraniRaitha,
    },
    {
      name: "Butter Podi Uthappam / Mushroom Kari Dosai",
      active: false,
      img: ButterPodiUthappam,
    },
    { name: "Banana Dosai / Oats Dosai", active: false, img: BananaDosai },
    {
      name: "Paneer Kothu Idiyappam / Mini Idly",
      active: false,
      img: PaneerKothuIdiyappam,
    },
    { name: "Idly", active: false, img: Idly },
    { name: "3 Types Of Chutney", active: false, img: Cutney },
    { name: "Sambar", active: false, img: sambhar },
    {
      name: "Bisibele Bath / Vathakulambu Sadam",
      active: false,
      img: BisibeleBath,
    },
    {
      name: "Cauliflower Fry / Appala Poo",
      active: false,
      img: CauliflowerFry,
    },
    {
      name: "Millet Curd Rice / Bagala Bath",
      active: false,
      img: MilletCurdRice,
    },
    { name: "Pickle", active: false, img: Pickle },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };

  return (
    <>
      <section className="wpo-product-section section-padding">
        <div class="accordion-body">
          <div className="d-flex flex-row-reverse ">
            <button className="button " onClick={() => setSheer(!sheer)}>
              Select Menu:
            </button>
          </div>
          <div className={` d-flex justify-content-around rows-10`}>
            {" "}
            <div className="d-flex">
              <div className="d-flex wpo-product-wrap">
                <div className="d-flex flex-wrap d-flex justify-content-around overflow-hidden ">
                  {data.map((ele, i) => {
                    return (
                      <>
                        <div
                          style={
                            sheer
                              ? {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                              : {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                          }
                        >
                          <div className="wpo-product-item">
                            <div className="wpo-product-img">
                              <img src={ele.img} alt="" />
                            </div>
                            <div className="wpo-product-text">
                              <h3> {ele.name}</h3>
                              {sheer ? (
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-html="true"
                                  type="submit"
                                  value={ele.name}
                                  onClick={(e) => {
                                    handleSheer(i);
                                  }}
                                  className="standardBreakfast button"
                                  title="Add to Cart"
                                >
                                  {ele.active ? "Unselect" : "Select"}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {!sheer ? null : (
                  <div
                    className="position-relative"
                    style={
                      sheer
                        ? { minWidth: 250, maxWidth: 250, margin: "0 10px" }
                        : { minWidth: 300, maxWidth: 300, margin: "0 10px" }
                    }
                  >
                    <div
                      className="office-info-item wpo-product-item position-fixed "
                      style={{ top: "280px" }}
                    >
                      <div className="">
                        <h4>Select Menu:</h4>
                      </div>
                      <div className=" justify-content-between ">
                        <ul>
                          {" "}
                          {data?.map((item, index) => {
                            if (item.active == false) return;
                            return (
                              <li>
                                {console.log(item)} {item.name}{" "}
                                <a
                                  key={index}
                                  onClick={() => handleSheer(index)}
                                >
                                  <i class="fa fa-trash-o"></i>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal1"
                          className="theme-btn"
                        >
                          Submit
                        </button>
                      </div>{" "}
                      {/* <LocationMap /> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Premium;
