import React, { Fragment } from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import PortfolioSectionS2 from "../../components/PortfolioSectionS2/PortfolioSectionS2";

import GalleryPageTitle from "../../components/pagetitle/GalleryPageTitle";

const GalleryPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <GalleryPageTitle pageTitle={"Gallery"} pagesub={"Gallery"} /> */}
      <PortfolioSectionS2 prClass={"pb-0 pt-120"} />
      {/* <PartnerSection pClass={"section-padding"} /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default GalleryPage;
