import React, { Fragment, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";

import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import PortfolioSection from "../../components/PortfolioSection";

import MenusPageTitle from "../../components/pagetitle/MenusPageTitle";


const PortfolioGridPage = () => {
  //
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <MenusPageTitle pageTitle={"Snaks"} pagesub={"Snaks"} /> */}
   
      <PortfolioSection ptClass={"pt-120"} />
     
                     
       
      {/* <PartnerSection pClass={"pb-120 pt-0"} /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PortfolioGridPage;
