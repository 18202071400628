import React, { useState } from "react";
import { Link } from "react-router-dom";

import Cashew from "../../../images/logo/Breakfast/Cashew.jpg";
import pongal from "../../../images/logo/Breakfast/pongal.jpg";
import vada from "../../../images/logo/Breakfast/vada.webp";
import puriMasala from "../../../images/logo/Breakfast/pooriMasala.jpg";
import kothu from "../../../images/logo/Breakfast/kothu.jpg";
import OnionUttapam from "../../../images/logo/Breakfast/OnionUttapam.jpg";
import Idli from "../../../images/logo/Breakfast/Idli.jpg";
import cutney from "../../../images/logo/Breakfast/cutney.jpg";
import filterCoffee from "../../../images/logo/Breakfast/filterCoffee.jpg";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import fruitKeasri from "../../../images/logo/Breakfast/fruitKesari.jpg";
import ShowCards from "../../MainMenu/showCards";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import OnionRaitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import BeansPoriyal from "../../../images/logo/Breakfast/beans_poriyal.jpg";
import PotatoKarakari from "../../../images/logo/Breakfast/potato_karakari.jpg";
import OilBrinjal from "../../../images/logo/Breakfast/oil_brinjal.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vadai.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import Rasam from "../../../images/logo/Breakfast/rasam.jpg";
import SemiyalPaalPayasam from "../../../images/logo/Breakfast/semiya_paal_payasam.jpg";
import ThalichalMoor from "../../../images/logo/Breakfast/thalicha_moor.jpg";
import ParupuGhee from "../../../images/logo/Breakfast/parupu_ghee.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import onionBhaji from "../../../images/logo/Breakfast/OnionBajji.jpg";
import buttermilk from "../../../images/logo/Breakfast/buttermilk.jpg";
import noorkal from "../../../images/logo/Breakfast/nookal.jpg";
import cauliflowerMasala from "../../../images/logo/Breakfast/CauliflowerPepperMasala.jpg"

const ShopProduct = () => {
  const [sheer, setSheer] = useState(false);
  const [data, setData] = useState([
    {
      name: "Kova Gulab Jamun",
      active: false,
      img: Jamun,
    },
    //noon-lunch
    {
      name: "Fruit Kesari",
      active: false,
      img: fruitKeasri,
    },
    { name: "Onion Raitha", active: false, img: OnionRaitha },
    {
      name: "Beans Poriyal / Cabbage Poriyal / Mixed Veg Poriyal",
      active: false,
      img: BeansPoriyal,
    },
    //noon lunch - Cauliflower Karakari , Mixed Veg Poriyal

    {
      name: "Potato Karakari / Cauliflower Karakari",
      active: false,
      img: PotatoKarakari,
    },
    { name: "Oil Brinjal", active: false, img: OilBrinjal },
    {
      name: "Masala Vadai",
      active: false,
      img: MasalaVada,
    },
    { name: "Pickle", active: false, img: Pickle },
    { name: "Rice", active: false, img: Rice },

    { name: "Onion Bajji", active: false, img: onionBhaji }, //noon-lunch
    { name: "Nookal Poriyal", active: false, img: noorkal }, //noon-lunch
    { name: "Cauliflower Pepper Masala", active: false, img: cauliflowerMasala }, //noon-lunch
    { name: "Sambar", active: false, img: sambhar },
    { name: "Vathal Kulambu", active: false, img: VathalKulambu },
    { name: "Rasam", active: false, img: Rasam },
    { name: "Semiya Paal Payasam", active: false, img: SemiyalPaalPayasam },
    { name: "Thalicha Moor", active: false, img: ThalichalMoor },

    { name: "Buttermilk", active: false, img: buttermilk }, //noon-lunch
    { name: "Parupu Chee", active: false, img: ParupuGhee },
    { name: "Appalam", active: false, img: Appalam },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handleStandardBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("standardBreakfast", JSON.stringify(clickedValues));
  };

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default ShopProduct;
