import React, { useState } from "react";
import ShowCards from "../../MainMenu/showCards";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import Raitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import FruitJam from "../../../images/logo/Breakfast/fruit_jam.jpg";
import CarrotCapsicumPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import SenaiChops from "../../../images/logo/Breakfast/senai_chops.jpg";
import CauliflowerMasalaKari from "../../../images/logo/Breakfast/cauliflower_masala_kari.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vada.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Briyani from "../../../images/logo/Breakfast/briyani.jpg";
import Thalcha from "../../../images/logo/Breakfast/thalcha.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import PineappleRasam from "../../../images/logo/Breakfast/pineapple_rasam.jpg";
import Curd from "../../../images/logo/Breakfast/curd.jpg";
import AdaiPrathamPayasam from "../../../images/logo/Breakfast/adai_pratham_payasam.jpg";
import Parupu from "../../../images/logo/Breakfast/parupu.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import palm from "../../../images/logo/Breakfast/palm.jpg";
import kavuni from "../../../images/logo/Breakfast/kavuni.webp";
import mysore_masala_dosa from "../../../images/logo/Breakfast/mysore-masala-dosa.webp";
import Kulipaniyaram from "../../../images/logo/Breakfast/Kulipaniyaram.jpg";
import vada from "../../../images/logo/Breakfast/vada.webp";
import pongal from "../../../images/logo/Breakfast/pongal.jpg";
import filterCoffee from "../../../images/logo/Breakfast/filterCoffee.jpg";
import cutney from "../../../images/logo/Breakfast/cutney.jpg";
import puriMasala from "../../../images/logo/Breakfast/pooriMasala.jpg";
import Rava_Dosa from "../../../images/logo/Breakfast/Rava-Dosa.jpg";
import idli_podi from "../../../images/logo/Breakfast/idli-podi.jpg";
import Chidambaram_Ghosthu from "../../../images/logo/Breakfast/Chidambaram_Ghosthu.jpg";

const Premium = () => {
  const [sheer, setSheer] = React.useState(false);
  const [data, setData] = useState([
    { name: "Karupatti Halwa / Palm Sugar Halwa", active: false, img: palm },
    {
      name: "Kavuni Arisi/ Moongil Arisi Akkara Vadisal",
      active: false,
      img: kavuni,
    },
    {
      name: "Pal Kolukattai / Pudi Kolukattai / Kulipaniyaram",
      active: false,
      img: Kulipaniyaram,
    },
    { name: "Vada / Bonda", active: false, img: vada },
    { name: "Pongal / Rava Pongal", active: false, img: pongal },
    {
      name: "Poori Masala/ Chola Poori – Rajma Masala",
      active: false,
      img: puriMasala,
    },
    { name: "Butter Rava Dosai", active: false, img: Rava_Dosa },
    {
      name: "Chettinadu Kari Dosai / Mysore Masal Dosai ",
      active: false,
      img: mysore_masala_dosa,
    },
    {
      name: "Mini Idly / ThattuIdly / Millet Idly",
      active: false,
      img: idli_podi,
    },
    { name: "3 Types Of Chutney", active: false, img: cutney },
    { name: "Spl. Podi With GingellyOil", active: false, img: idli_podi },
    { name: "Chidambaram Ghosthu ", active: false, img: Chidambaram_Ghosthu },
    { name: "Sambar", active: false, img: sambhar },
    { name: "Tea & Filter Coffee", active: false, img: filterCoffee },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };

  return (
    // <>
    //   <section className="wpo-product-section section-padding">
    //     <div class="accordion-body">
    //       <div className="d-flex flex-row-reverse ">
    //         <button className="button " onClick={() => setSheer(!sheer)}>
    //           Select Menu:
    //         </button>
    //       </div>
    //       <div className={` d-flex justify-content-around rows-10`}>
    //         {" "}
    //         <div className="d-flex">
    //           <div className="d-flex wpo-product-wrap">
    //             <div className="d-flex flex-wrap d-flex justify-content-around overflow-hidden ">
    //               {data.map((ele, i) => {
    //                 return (
    //                   <>
    //                     <div
    //                       style={
    //                         sheer
    //                           ? {
    //                               minWidth: 300,
    //                               maxWidth: 300,
    //                               margin: "0 10px",
    //                             }
    //                           : {
    //                               minWidth: 300,
    //                               maxWidth: 300,
    //                               margin: "0 10px",
    //                             }
    //                       }
    //                     >
    //                       <div className="wpo-product-item">
    //                         <div className="wpo-product-img">
    //                           <img src={ele.img} alt="" />
    //                         </div>
    //                         <div className="wpo-product-text">
    //                           <h3> {ele.name}</h3>
    //                           {sheer ? (
    //                             <button
    //                               data-bs-toggle="tooltip"
    //                               data-bs-html="true"
    //                               type="submit"
    //                               value={ele.name}
    //                               onClick={(e) => {
    //                                 handleSheer(i);
    //                               }}
    //                               className="standardBreakfast button"
    //                               title="Add to Cart"
    //                             >
    //                               {ele.active ? "Unselect" : "Select"}
    //                             </button>
    //                           ) : null}
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </>
    //                 );
    //               })}
    //             </div>
    //             {!sheer ? null : (
    //               <div
    //                 className="position-relative"
    //                 style={
    //                   sheer
    //                     ? { minWidth: 250, maxWidth: 250, margin: "0 10px" }
    //                     : { minWidth: 300, maxWidth: 300, margin: "0 10px" }
    //                 }
    //               >
    //                 <div
    //                   className="office-info-item wpo-product-item position-fixed "
    //                   style={{ top: "280px" }}
    //                 >
    //                   <div className="">
    //                     <h4>Select Menu:</h4>
    //                   </div>
    //                   <div className=" justify-content-between ">
    //                     <ul>
    //                       {" "}
    //                       {data?.map((item, index) => {
    //                         if (item.active == false) return;
    //                         return (
    //                           <li>
    //                             {console.log(item)} {item.name}{" "}
    //                             <a
    //                               key={index}
    //                               onClick={() => handleSheer(index)}
    //                             >
    //                               <i class="fa fa-trash-o"></i>
    //                             </a>
    //                           </li>
    //                         );
    //                       })}
    //                     </ul>
    //                     <button
    //                       type="button"
    //                       data-bs-toggle="modal"
    //                       data-bs-target="#myModal1"
    //                       className="theme-btn"
    //                     >
    //                       Submit
    //                     </button>
    //                   </div>{" "}
    //                   {/* <LocationMap /> */}
    //                 </div>
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </>
    <ShowCards data={data} setData={setData} />
  );
};

export default Premium;
