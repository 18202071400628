import React from "react";
import { Slide } from "react-awesome-reveal";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper React components
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
const Hero2 = () => {
  return (
    <section
      className="static-hero"
      style={{
        backgroundColor: "transparent",
        backgroundColor: "white",
        maxHeight: "90vh",
      }}
    >
      <div className="hero-container">
        <div className="hero-inner">
          <div
            style={{ margin: "0", padding: "0" }}
            className="container-fluid"
          >
            <div className="row align-items-center">
              <Swiper
                spaceBetween={50}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation
                modules={[Autoplay, Navigation]}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {/* <SwiperSlide>
                  <div className="col-xl-12 col-lg-12 col-12">
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src="https://th.bing.com/th/id/OIP.mXk6kBcrindk6DZZUj6gKgHaE0?w=260&h=180&c=7&r=0&o=5&dpr=1.1&pid=1.7"
                    ></img>
                    <div
                      className="div position-absolute d-flex justify-content-center align-items-center top-0 w-100 "
                      style={{ height: "100%", backgroundColor: "#00000045" }}
                    >
                      <div className="wpo-static-hero-inner mt-0 pt-0">
                        <div className="slide-title">
                          <Slide
                            direction="up"
                            duration="1200"
                            triggerOnce="true"
                          >
                            <h2 className="exo-2-new">
                              Welcome To GTS Catering
                            </h2>
                          </Slide>
                        </div>
                        <div className="slide-text">
                          <Slide
                            direction="up"
                            duration="1400"
                            triggerOnce="true"
                          >
                            <p>Flavour of tradition...</p>
                          </Slide>
                        </div>
                        <div className="wpo-wedding-date"></div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide> */}
                <SwiperSlide>
                  <div className="col-xl-12 col-lg-12 col-12">
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src="https://cdn.pixabay.com/photo/2013/09/05/10/38/catering-179046_640.jpg"
                    ></img>
                    <div
                      className="div position-absolute d-flex justify-content-center align-items-center top-0 w-100 "
                      style={{ height: "100%", backgroundColor: "#00000045" }}
                    >
                      <div className="wpo-static-hero-inner mt-0 pt-0">
                        <div className="slide-title">
                          <Slide
                            direction="up"
                            duration="1200"
                            triggerOnce="true"
                          >
                            <h2 className="exo-2-new">
                              Welcome To GTS Catering
                            </h2>
                          </Slide>
                        </div>
                        <div className="slide-text">
                          <Slide
                            direction="up"
                            duration="1400"
                            triggerOnce="true"
                          >
                            <p>Flavour of tradition...</p>
                          </Slide>
                        </div>
                        <div className="wpo-wedding-date"></div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-lg-12 col-12">
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src="https://cdn.pixabay.com/photo/2015/01/16/15/02/eating-601578_640.jpg"
                    ></img>
                    <div
                      className="div position-absolute d-flex justify-content-center align-items-center top-0 w-100 "
                      style={{ height: "100%", backgroundColor: "#00000045" }}
                    >
                      <div className="wpo-static-hero-inner mt-0 pt-0">
                        <div className="slide-title">
                          <Slide
                            direction="up"
                            duration="1200"
                            triggerOnce="true"
                          >
                            <h2 className="exo-2-new">
                              Welcome To GTS Catering
                            </h2>
                          </Slide>
                        </div>
                        <div className="slide-text">
                          <Slide
                            direction="up"
                            duration="1400"
                            triggerOnce="true"
                          >
                            <p>Flavour of tradition...</p>
                          </Slide>
                        </div>
                        <div className="wpo-wedding-date"></div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-lg-12 col-12">
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      src="https://cdn.pixabay.com/photo/2013/09/05/10/38/restaurant-bern-179047_640.jpg"
                    ></img>
                    <div
                      className="div position-absolute d-flex justify-content-center align-items-center top-0 w-100 "
                      style={{ height: "100%", backgroundColor: "#00000045" }}
                    >
                      <div className="wpo-static-hero-inner mt-0 pt-0">
                        <div className="slide-title">
                          <Slide
                            direction="up"
                            duration="1200"
                            triggerOnce="true"
                          >
                            <h2 className="exo-2-new">
                              Welcome To GTS Catering
                            </h2>
                          </Slide>
                        </div>
                        <div className="slide-text">
                          <Slide
                            direction="up"
                            duration="1400"
                            triggerOnce="true"
                          >
                            <p>Flavour of tradition...</p>
                          </Slide>
                        </div>
                        <div className="wpo-wedding-date"></div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="static-hero-right">
        <div className="static-hero-img scene" id="scene">
          <div className="static-hero-img-inner">
            <img src={hero1} alt="" />
          </div>
        </div>
      </div> */}
      <div className="right-shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 650 597"
          fill="none"
        >
          <path d="M717 0C717 0 527.91 0 475.696 129.736C423.481 259.472 501.092 358.185 396.683 423.034C292.274 487.902 74.9839 485.573 26.2847 646.096C-22.3947 806.598 11.4538 863 11.4538 863L653.509 803.776L717 0Z" />
        </svg>
      </div>
    </section>
  );
};

export default Hero2;
