import React, { useState } from "react";
import { Link } from "react-router-dom";

import Cashew from "../../../images/logo/Breakfast/Cashew.jpg";
import pongal from "../../../images/logo/Breakfast/pongal.jpg";
import vada from "../../../images/logo/Breakfast/vada.webp";
import puriMasala from "../../../images/logo/Breakfast/pooriMasala.jpg";
import kothu from "../../../images/logo/Breakfast/kothu.jpg";
import OnionUttapam from "../../../images/logo/Breakfast/OnionUttapam.jpg";
import Idli from "../../../images/logo/Breakfast/Idli.jpg";
import cutney from "../../../images/logo/Breakfast/cutney.jpg";
import filterCoffee from "../../../images/logo/Breakfast/filterCoffee.jpg";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import ShowCards from "../../MainMenu/showCards";
import palm from "../../../images/logo/Breakfast/palm.jpg";
import kavuni from "../../../images/logo/Breakfast/kavuni.webp";
import Rava_Dosa from "../../../images/logo/Breakfast/Rava-Dosa.jpg";
import Kulipaniyaram from "../../../images/logo/Breakfast/Kulipaniyaram.jpg";
import mysore_masala_dosa from "../../../images/logo/Breakfast/mysore-masala-dosa.webp";
import Multi_Millet_Idli from "../../../images/logo/Breakfast/Multi-Millet-Idli.jpg";
import idli_podi from "../../../images/logo/Breakfast/idli-podi.jpg";
import Chidambaram_Ghosthu from "../../../images/logo/Breakfast/Chidambaram_Ghosthu.jpg";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import OnionRaitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import BeansPoriyal from "../../../images/logo/Breakfast/beans_poriyal.jpg";
import PotatoKarakari from "../../../images/logo/Breakfast/potato_karakari.jpg";
import OilBrinjal from "../../../images/logo/Breakfast/oil_brinjal.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vadai.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import Rasam from "../../../images/logo/Breakfast/rasam.jpg";
import SemiyalPaalPayasam from "../../../images/logo/Breakfast/semiya_paal_payasam.jpg";
import ThalichalMoor from "../../../images/logo/Breakfast/thalicha_moor.jpg";
import ParupuGhee from "../../../images/logo/Breakfast/parupu_ghee.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";

const ShopProduct = () => {
  const [sheer, setSheer] = useState(false);
  const [data, setData] = useState([
    {
      name: "Cashew Halwa / DryfruitKesari / CarrotHalwa",
      active: false,
      img: Cashew,
    },
    { name: "Pongal", active: false, img: pongal },
    { name: "Vada", active: false, img: vada },
    { name: "Poori - Masala", active: false, img: puriMasala },
    { name: "Kothu Idiyappam / Kichadi / Upma", active: false, img: kothu },
    {
      name: "Rava Dosai / Onion Podi Uthappam",
      active: false,
      img: OnionUttapam,
    },
    { name: "Idly", active: false, img: Idli },
    { name: "3 Types Of Chutney", active: false, img: cutney },
    { name: "Sambar", active: false, img: sambhar },
    { name: "Filter Coffee", active: false, img: filterCoffee },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handleStandardBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("standardBreakfast", JSON.stringify(clickedValues));
  };

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default ShopProduct;
