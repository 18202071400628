import React, { useState } from "react";
import ShowCards from "../../MainMenu/showCards";
import PaneerJamun from "../../../images/logo/Breakfast/paneer_jamun.jpg";
import kalkanduPongal from "../../../images/logo/Breakfast/kalkandu_pongal.jpg";
import KismissRaitha from "../../../images/logo/Breakfast/kismis_raitha.jpg";
import DragonFruitJam from "../../../images/logo/Breakfast/dragon_fruit_jam.jpg";
import CarrotCapsicumPattaniPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import BrocolliMasalaKari from "../../../images/logo/Breakfast/brocolli_masalakari.jpg";
import MushroomPallipalayam from "../../../images/logo/Breakfast/mushroom_pallipalayam.jpg";
import VendaikaiNilakadalaiPulimandi from "../../../images/logo/Breakfast/vendaikai_nilakadalai_pulimandi.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import CurdVadai from "../../../images/logo/Breakfast/curd_vadai.jpg";
import RambuttanPickle from "../../../images/logo/Breakfast/rambuttan_pickle.jpg";
import ChappathiDalMakhani from "../../../images/logo/Breakfast/chappathi_dal_makhani.jpg";
import karupeypillaiRice from "../../../images/logo/Breakfast/karupeypillai_rice.jpg";
import VadakaThuvayal from "../../../images/logo/Breakfast/vadaka_thuvayal.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import Sambar from "../../../images/logo/Breakfast/sambhar.webp";
import VendhayaThokku from "../../../images/logo/Breakfast/endhaya_thokku.jpg";
import MoorKulambu from "../../../images/logo/Breakfast/moor_kulambu.jpg";
import NellikaiRasam from "../../../images/logo/Breakfast/nellikai_rasam.jpg";
import ElaneerPayasam from "../../../images/logo/Breakfast/elaneer_payasam.jpg";
import PotCurd from "../../../images/logo/Breakfast/pot_curd.jpg";
import Ghee from "../../../images/logo/Breakfast/ghee.jpg";
import bagalaBath from "../../../images/logo/Breakfast/bagala_bath.jpg";
import fourtypeCutney from "../../../images/logo/Breakfast/4typeschutney.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import SweetCornSoup from "../../../images/logo/Breakfast/sweet_corn_soup.jpg";
import KarupattiHalwa from "../../../images/logo/Breakfast/karupatti_halwa.jpg";
import KajuPistaRoll from "../../../images/logo/Breakfast/kaju_pistaRoll.jpg";
import RoseRasamalai from "../../../images/logo/Breakfast/rose_rasamalai.jpg";
import VegGalotiKabab from "../../../images/logo/Breakfast/veg-galotiKabab.jpg";
import CheesePoppers from "../../../images/logo/Breakfast/cheese_poppers.jpg";
import NoodlesBalls from "../../../images/logo/Breakfast/noodles_balls.jpg";
import PotBiriyani from "../../../images/logo/Breakfast/pot_biriyani.jpg";
import VegHakkaNoodles from "../../../images/logo/Breakfast/veg_hakkaNoodles.jpg";
import Chappathi from "../../../images/logo/Breakfast/chappathi.jpg";
import KajuMasala from "../../../images/logo/Breakfast/kaju_masala.jpg";
import CoinPoratta from "../../../images/logo/Breakfast/coin_parotta.jpg";
import ButterPodiDosai from "../../../images/logo/Breakfast/butter_podi_dosai.jpg";
import MultigrainAdai from "../../../images/logo/Breakfast/multigrain_adai.jpg";
import Idly from "../../../images/logo/Breakfast/Idli.jpg";
import CurryLeavesKettiKulambu from "../../../images/logo/Breakfast/curry_leaves_ketti_kulambu.jpg";
import Cutney from "../../../images/logo/Breakfast/cutney.jpg";
import Idiyappam from "../../../images/logo/Breakfast/idiyappam.webp";
import BisibeleBath from "../../../images/logo/Breakfast/bisibele_bath.jpg";
import BrinjalPeanutPulimandi from "../../../images/logo/Breakfast/brinjal_peanut_pulimandi.jpg";
import VathalkulambuSadam from "../../../images/logo/Breakfast/vathalkulambu_Sadam.jpg";
import AppalaPoo from "../../../images/logo/Breakfast/appala_Poo.jpg";
import MilletCurdRice from "../../../images/logo/Breakfast/millet_curd_rice.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import CreamOfBrocolliSoup from "../../../images/logo/Breakfast/Cream_Of_Brocolli_Soup.jpg";
import AmericanDryFruitHalwa from "../../../images/logo/Breakfast/American_Dry.jpg";
import GheeMysurpa from "../../../images/logo/Breakfast/Ghee_Mysurpa.jpg";
import MalaiSandwich from "../../../images/logo/Breakfast/Malai_Sandwich.jpg";
import BabycornFritters from "../../../images/logo/Breakfast/BabycornFritters.jpg";
import PaneerTikka from "../../../images/logo/Breakfast/Paneer_Tikka.jpg";
import VermicelliBalls from "../../../images/logo/Breakfast/Vermicelli_Balls.jpg";
import ThonnaBiriyani from "../../../images/logo/Breakfast/Thonna_Biriyani.jpg";
import AlooParatha from "../../../images/logo/Breakfast/Aloo_Paratha.jpg";
import Phulka from "../../../images/logo/Breakfast/phulka.jpg";
import RajmaMalaiMasala from "../../../images/logo/Breakfast/Rajma_Malai_Masala.jpg";
import WheatParotta from "../../../images/logo/Breakfast/wheat_parotta.jpg";
import BindiDoPayaza from "../../../images/logo/Breakfast/Bindi_Do_Payaza.jpg";
import GheeMasalDosai from "../../../images/logo/Breakfast/Ghee_Masal_Dosa.jpg";
import VazhaiPooThinaiAdai from "../../../images/logo/Breakfast/Vazhai_Poo_Thinai_Adai.jpg";
import KaimaIdli from "../../../images/logo/Breakfast/Kaima_Idli.jpg";
import DrumstickKettiKulambu from "../../../images/logo/Breakfast/Drumstick_Ketti_Kulambu.jpg";
import RagiIdiyappam from "../../../images/logo/Breakfast/Ragi_Idiyappam.jpg";
import MixedVegCurry from "../../../images/logo/Breakfast/Mixed_Veg_Curry.jpg";
import SemiyaCurdRice from "../../../images/logo/Breakfast/Semiya_Curd_Rice.jpg";

const Luxury = () => {
  const [data, setData] = useState([
    {
      name: "Sweet Corn Soup / Veg Clear Soup / Tomato Soup /BananaStem Soup",
      active: false,
      img: PaneerJamun,
    },
    {
      name: "Karupatti Halwa / Badam Halwa / Fig Halwa",
      active: false,
      img: kalkanduPongal,
    },
    {
      name: "Kaju PistaRoll / Badam Katli",
      active: false,
      img: KismissRaitha,
    },
    {
      name: "Rose Rasamalai / Malai Chum Chum",
      active: false,
      img: DragonFruitJam,
    },
    {
      name: "Veg GalotiKabab / PaneerCutlet",
      active: false,
      img: CarrotCapsicumPattaniPoriyal,
    },
    {
      name: "Cheese Poppers/ Chilly Garlic Mushroom",
      active: false,
      img: BrocolliMasalaKari,
    },
    {
      name: "Noodles Balls / Veg Nuggets",
      active: false,
      img: MushroomPallipalayam,
    },
    {
      name: "Pot Biriyani/ Raw Jackfruit Biriyani",
      active: false,
      img: VendaikaiNilakadalaiPulimandi,
    },
    {
      name: "Veg HakkaNoodles / Chilli Parotta",
      active: false,
      img: MalabarAviyal,
    },
    {
      name: "Chappathi / Methi Rotti",
      active: false,
      img: ValaiPooChips,
    },
    {
      name: "Kaju Masala/ Paneer PepperMasala",
      active: false,
      img: CurdVadai,
    },
    {
      name: "Coin Parotta/ Bun Parotta",
      active: false,
      img: RambuttanPickle,
    },
    {
      name: "Capsicum MutterMasala / Lauki Kofta Curry",
      active: false,
      img: ChappathiDalMakhani,
    },
    {
      name: "Butter Podi Dosai / Mudakathan Dosai",
      active: false,
      img: karupeypillaiRice,
    },
    {
      name: "Multigrain Adai / Banana Dosai",
      active: false,
      img: VadakaThuvayal,
    },
    { name: "Button Idli / Veg Idli / Elaneer Idli", active: false, img: Rice },
    { name: "Curry Leaves Ketti Kulambu", active: false, img: Sambar },
    { name: "4 Types of Chutney", active: false, img: fourtypeCutney },
    { name: "Sambar", active: false, img: Sambar },
    {
      name: "Idiyappam - Veg Stew",
      active: false,
      img: VendhayaThokku,
    },
    {
      name: "Bisibele Bath",
      active: false,
      img: MoorKulambu,
    },
    {
      name: "Brinjal Peanut Pulimandi",
      active: false,
      img: NellikaiRasam,
    },
    {
      name: "Vathalkulambu Sadam",
      active: false,
      img: ElaneerPayasam,
    },
    { name: "Appala Poo", active: false, img: AppalaPoo },
    { name: "Millet Curd Rice / Bagala Bath", active: false, img: bagalaBath },
    {
      name: "Cream Of Brocolli Soup / Hot And Sour Soup / MushroomPepper Soup / Thuthuvalai Soup",
      active: false,
      img: CreamOfBrocolliSoup,
    },
    {
      name: "Capsicum Halwa / Palm Sugar Halwa / American Dry Fruit Halwa",
      active: false,
      img: AmericanDryFruitHalwa,
    },
    { name: "Ghee Mysurpa / Ajmeer Burfi", active: false, img: GheeMysurpa },
    { name: "Malai Sandwich/ Badam Burfi", active: false, img: MalaiSandwich },
    {
      name: "Babycorn Fritters/ Cheese Mutter Cutlet",
      active: false,
      img: BabycornFritters,
    },
    { name: "Paneer Tikka / Hara Bara Kebab", active: false, img: PaneerTikka },
    {
      name: "Vermicelli Balls / Veg Lollipop",
      active: false,
      img: VermicelliBalls,
    },
    {
      name: "Thonna Biriyani/ Schezan FriedRice",
      active: false,
      img: ThonnaBiriyani,
    },
    {
      name: "Aloo Paratha/ Mushroom ChilliChappathi",
      active: false,
      img: AlooParatha,
    },
    { name: "Phulka / Romali Roti", active: false, img: Phulka },
    {
      name: "Rajma Malai Masala / Paneer Lababdar",
      active: false,
      img: RajmaMalaiMasala,
    },
    { name: "Wheat Parotta/ Veg Lappa", active: false, img: WheatParotta },
    {
      name: "Bindi Do Payaza / Corn CapsicumMasala",
      active: false,
      img: BindiDoPayaza,
    },
    {
      name: "Ghee Masal Dosai / MLA Pesarattu Dosa",
      active: false,
      img: GheeMasalDosai,
    },
    {
      name: "Vazhai Poo Thinai Adai / Veg Kari Dosai",
      active: false,
      img: VazhaiPooThinaiAdai,
    },
    {
      name: "Kaima Idli / Fried Idli / Pathaneer Idli",
      active: false,
      img: KaimaIdli,
    },
    {
      name: "Drumstick Ketti Kulambu",
      active: false,
      img: DrumstickKettiKulambu,
    },
    { name: "4 Types of Chutney", active: false, img: fourtypeCutney },
    { name: "Sambar", active: false, img: Sambar },
    { name: "Ragi Idiyappam / White Kurma", active: false, img: RagiIdiyappam },
    { name: "Bisibele Bath", active: false, img: BisibeleBath },
    { name: "Mixed Veg Curry", active: false, img: MixedVegCurry },
    { name: "Vathalkulambu Sadam", active: false, img: VathalkulambuSadam },
    { name: "Appala Poo", active: false, img: AppalaPoo },
    {
      name: "Semiya Curd Rice / Bagala Bath",
      active: false,
      img: SemiyaCurdRice,
    },
    { name: "Pickle", active: false, img: Pickle },
  ]);

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };
  const [number, setCount] = useState(8);
  const [buttonActive, setButtonState] = useState(false);

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default Luxury;
