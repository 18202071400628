import React from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import shape1 from "../../images/footer-shape-1.svg";
import shape2 from "../../images/footer-shape-2.svg";
import logo from "../../../src/images/logo/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import gallery17 from "../../images/logo/gallery/gallery17.JPG";
import gallery18 from "../../images/logo/gallery/gallery18.JPG";
import gallery19 from "../../images/logo/gallery/gallery19.JPG";
import gallery20 from "../../images/logo/gallery/gallery20.JPG";
import gallery21 from "../../images/logo/gallery/gallery21.JPG";
// import gallery22 from "../../images/logo/gallery/gallery22.HEIC";
import gallery23 from "../../images/logo/gallery/gallery23.PNG";
import gallery24 from "../../images/logo/gallery/gallery24.JPG";
import gallery25 from "../../images/logo/gallery/gallery25.JPG";
import gallery26 from "../../images/logo/gallery/gallery26.PNG";
import gallery27 from "../../images/logo/gallery/gallery27.PNG";
import gallery28 from "../../images/logo/gallery/gallery28.JPG";
const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (<>
  <div className="position-relative mt-5">
  <Swiper
      spaceBetween={0}
     loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
      }}
      // centeredSlides={true}
      speed={2000}
      
      modules={[Autoplay]}
    
      breakpoints={
        {
          768:{
            slidesPerView:8
          },
          0:{
            slidesPerView:4
          }
        }
      }
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery24}></img>
                  

                        
           
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery23}></img>
                 
                       

                
              
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery19}></img>
                 
                  

               

                
             
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery21}></img>
                 
                  

               

                
             
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery20}></img>
                 
                  

               

                
              
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery27}></img>
                 
                       

                
              
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery25}></img>
                 
                       

                
              
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery19}></img>
                 
                  

               

                
              
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery18}></img>
                 
                  

               

                
             
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
                <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery17}></img>
                 
                  

               

                
             
            </SwiperSlide>
            <SwiperSlide style={{height:"intial"}}> 
      
      <img style={{width:"100%",maxHeight:"100%",height:"200px",objectFit:"cover"}} src={gallery17}></img>
       
        

     

      
   
  </SwiperSlide>
    
    </Swiper>
<div className="position-absolute inst-btn" >
  <div className="button-ani">
    <button className="button-oni" onClick={()=>{window.location.href="https://instagram.com/gts_catering"}}> <i className="ti-instagram"></i>&nbsp;Follow On Instagram</button>
  </div>
</div>
    </div>
    <footer className={`wpo-site-footer  ${props.footerClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row ">
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3 className="">Links</h3>
                </div>
                <div className="link-wrap">
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/menus">
                        Menus
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event">
                        Services
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/gallery">
                        Gallery
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/service">
                        Services
                      </Link>
                    </li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link className="logo" onClick={ClickHandler} to="/">
                    <img className="" src={logo} />
                  </Link>
                </div>
                <p>
                  To Serve Happiness to our Customers through Delicious,Quality
                  food with Extraordinary Hospitality Experience.
                </p>
                <ul>
                  <li>
                    <a
                      onClick={ClickHandler}
                      target="_blank"
                      href="https://web.whatsapp.com/send?autoload=1&app_absent=0&phone=918056376622&text"
                    >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ClickHandler}
                      target="_blank"
                      href="https://www.facebook.com/gtscatering?mibextid=LQQJ4d   "
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ClickHandler}
                      target="_blank"
                      href="https://youtube.com/@gtscatering?si=C8JQ9m8WD7vuSmgk"
                    >
                      <i className="ti-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/gts_catering"
                      target="_blank"
                      onClick={ClickHandler}
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3> Contact </h3>
                </div>
                <div className="contact-ft ">
                  <p className="">
                    <i className="ti-email p-2"></i>{" "}
                    <a
                      href="mailto:gtscatering1993@gmail.com"
                      style={{ color: "white" }}
                    >
                      gtscatering1993@gmail.com
                    </a>{" "}
                  </p>
                  <p>
                    {" "}
                    <i className="fa fa-phone p-2"></i>{" "}
                    <a
                      href="tel:+919443190255"
                      style={{ color: "white" }}
                      className="me-2"
                    >
                      9443190255
                    </a>
                    {"    "}
                    <a href="tel:+919842990255" style={{ color: "white" }}>
                      9842990255
                    </a>
                  </p>
                  <p>
                    <i className="ti-location-pin p-2 "></i>
                    <span>
                      26, Perumal West Street, Nagapattinam,{" "}
                      <br className="d-lg-none" />
                      Tamil Nadu, India
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row ">
            <div className="col col-sm-12 col-xs-12">
              <p className="copyright ">
                {" "}
                All right reserved &copy;<span>2024</span>
                <Link onClick={ClickHandler} to="/">
                  {"  "} GTS Catering{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
   
    </>
  );
};

export default Footer;
