import React, { Fragment, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";

import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import PortfolioSection from "../../components/PortfolioSection/ind.js";

import MenusPageTitle from "../../components/pagetitle/MenusPageTitle";
import SpecialMenusIndex from "../../components/ShopProduct/SpecialMenu/index.js";

const SpecialMenus = () => {
  //
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <MenusPageTitle pageTitle={" Special Menus"} pagesub={"Special Menus"} /> */}

      {/* <PortfolioSection ptClass={"pt-120"} /> */}
      <SpecialMenusIndex />

      {/* <PartnerSection pClass={"pb-120 pt-0"} /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default SpecialMenus;
