import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import logo2 from "../../../src/images/logo/logo.png";

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const [cartActive, setcartState] = useState(false);
  const nav = useNavigate();
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const navTest = () => {
    nav("/home#testmonial");
  };

  const { carts } = props;

  return (
    <header id="header" className={props.topbarNone}>
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center" style={{ width: "100%" }}>
              <div className="col-lg-3 col-md-3 col-4 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-3">
                {" "}
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <NavLink onClick={ClickHandler} to="/">
                        Home
                      </NavLink>
                    </li>{" "}
                    <li className="menu-item-has-children">
                      <NavLink onClick={ClickHandler} to="/about">
                        About Us
                      </NavLink>
                    </li>{" "}
                    <li className="menu-item-has-children">
                      <NavLink onClick={ClickHandler} to="/gallery">
                        Gallery
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-5">
                <div className="navbar-header row align-items-center">
                  <NavLink
                    onClick={ClickHandler}
                    className="navbar-brand "
                    to="/home"
                  >
                    <img
                      className=" nav-img p-2 rounded mx-md-auto mr-2 d-block"
                      src={logo2}
                    />
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-5 col-md-8 col-4">
                <div className="header-right">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li
                        onClick={ClickHandler}
                        className="menu-item-has-children"
                      >
                        <NavLink
                          onClick={ClickHandler}
                          className="navbar-brand "
                          to="/testmonials"
                        >
                          Testimonial
                        </NavLink>
                      </li>
                      <li className="menu-item-has-children">
                        <a onClick={ClickHandler}>Menus</a>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={ClickHandler} to="/breakfast">
                              BreakFast
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/lunch">
                              Lunch
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/dinner">
                              Dinner
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/snacks">
                              Snacks
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/specialMenus">
                              Special Menus
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/chatStall">
                              Chat Stall
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/welcomeDrinks">
                              Welcome Drinks
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink onClick={ClickHandler} to="/event">
                          Services
                        </NavLink>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink onClick={ClickHandler} to="/contact">
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, { removeFromCart })(Header);
