import React, { Fragment } from "react";
import { connect } from "react-redux";
import PageTitle from "../../../components/pagetitle/PageTitle";
import Scrollbar from "../../../components/scrollbar/scrollbar";
import { addToCart, addToWishList } from "../../../store/actions/action";
import ShopProduct from "../../../components/ShopProduct/breakfast";
import api from "../../../api";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import Premium from "../../../components/ShopProduct/breakfast/Premium";
import Luxury from "../../../components/ShopProduct/breakfast/Luxury";
import ChatStallIndex from "../../../components/ShopProduct/ChatStall";

const ChartStall = ({ addToCart, addToWishList }) => {
  const productsArray = api();

  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
  };

  const addToWishListProduct = (product) => {
    addToWishList(product);
  };
  const [breakfast, setBreakfast] = React.useState("standard");

  const products = productsArray;

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <PageTitle pageTitle={"Breakfast"} pagesub={"Breakfast"} /> */}
      <section className="wpo-shop-page section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ChatStallIndex />
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default connect(null, { addToCart, addToWishList })(ChartStall);
