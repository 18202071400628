import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";

import BlogList from "../../components/BlogList/BlogList.js";
import Footer from "../../components/footer/Footer.js";
import Scrollbar from "../../components/scrollbar/scrollbar";

import TeamSection from "../../components/TeamSection/TeamSection.js";
import EventsPageTitle from "../../components/pagetitle/EventsPageTitle.js";

const BlogPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <EventsPageTitle pageTitle={"Services"} pagesub={"Services"} /> */}

      <BlogList />
      {/* <StorySection3 /> */}
      <TeamSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
