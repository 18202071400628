import React, { Fragment } from "react";
import { connect } from "react-redux";
import PageTitle from "../../../components/pagetitle/PageTitle";
import Scrollbar from "../../../components/scrollbar/scrollbar";
import { addToCart, addToWishList } from "../../../store/actions/action";
import ShopProduct from "../../../components/ShopProduct/breakfast";
import api from "../../../api";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import Premium from "../../../components/ShopProduct/breakfast/Premium";
import Luxury from "../../../components/ShopProduct/breakfast/Luxury";

const Snacks = ({ addToCart, addToWishList }) => {
  const productsArray = api();

  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
  };

  const addToWishListProduct = (product) => {
    addToWishList(product);
  };
  const [breakfast, setBreakfast] = React.useState("standard");

  const products = productsArray;

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      {/* <PageTitle pageTitle={"Breakfast"} pagesub={"Breakfast"} /> */}
      <nav className="toggleNav">
        <ul className="d-flex justify-content-center fs-2 ">
          <li
            className={`p-4 cursor-point ${breakfast == "standard" ? "text-warning" : ""}`}
            style={{cursor:"pointer"}}
            id="standard"
            onClick={() => setBreakfast("standard")}
          >
            Standard Breakfast
          </li>
          <li className="p-4 cursor-point">
            <i className="	fa fa-angle-double-right"></i>
          </li>
          <li
            className={`p-4 cursor-point ${breakfast == "premium" ? "text-warning" : ""}`}
            style={{cursor:"pointer"}}
            id="premium"
            onClick={() => setBreakfast("premium")}
          >
            Premium BreakFast
          </li>
          <li className="p-4 cursor-point">
            <i className="	fa fa-angle-double-right"></i>
          </li>
          <li
            className={`p-4 cursor-point ${breakfast == "luxury" ? "text-warning" : ""}`}
            style={{cursor:"pointer"}}
            id="luxury"
            onClick={() => setBreakfast("luxury")}
          >
            Luxury Breakfast{" "}
          </li>
        </ul>
      </nav>
      <section className="wpo-shop-page section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {breakfast == "standard" && <ShopProduct />}
              {breakfast == "premium" && <Premium />}
              {breakfast == "luxury" && <Luxury />}
            </div>
          </div>
        </div>
      </section>
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};

export default connect(null, { addToCart, addToWishList })(Snacks);
