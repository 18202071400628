import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import Team from "../../api/team";
import SectionTitle from "../SectionTitle";
import wedding from "../../images/logo/wedding.png";
import social from "../../images/logo/wedding.jpg";
import corporate from "../../images/logo/gallery/gallery27.PNG";
import family from "../../images/logo/services-get-together.jpg";
import festival from "../../images/logo/festivals.jpg";
import lunch from "../../images/logo/lunch.jpg";
import anniversary from "../../images/logo/anniversary.jpg";
import birthday from "../../images/logo/birthday2.png";
import shape1 from "../../images/story/flower-shape5.svg";
import shape2 from "../../images/story/flower-shape6.svg";
import { HashLink } from "react-router-hash-link";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const handleNavigate = (e) => {
  e.stopPropagation();
  window.scrollTo(10, 0);
};

const Storys = [
  {
    sImg: wedding,
    // shape: shape1,
    animation: "1000",
    title: "Wedding",

    
  },
  {
    sImg: social,
    animation: "1200",
    title: "Social ",
    // date: "12 Dec 2019",
    
    // order1: "order-lg-2 order-1",
    // order2: "order-lg-1 order-2",
  },
  {
    sImg: corporate,
    // shape: shape2,
    animation: "1400",
    title: "Corporate",
    // date: "16 Jan 2023",
    
  },
  {
    sImg: birthday,
    // shape: shape2,
    animation: "1400",
    title: "Birthdays",
   
  },
  {
    sImg: anniversary,
    // shape: shape1,
    animation: "1000",
    title: "Anniversary",

   
  },
  {
    sImg: festival,
    // shape: shape1,
    animation: "1000",
    title: "Festival Celebrations",

    
  },
  {
    sImg: lunch,
    // shape: shape1,
    animation: "1000",
    title: "Lunch & Dinner Parties",

    
  },
  {
    sImg: family,
    // shape: shape1,
    animation: "1000",
    title: "Family Get-Together",

    
  },
];

const TeamSection = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  return (
    <section className="wpo-team-section-s2 ">
      <div className="container">
        <h2 className="text-center team-heading">Services</h2>
        <div className="wpo-team-wrap">
          <div className="row">
            {Storys.map((story, st) => (
              <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={st}>
                <Slide
                  direction="up"
                  duration={story.animation}
                  triggerOnce="true"
                >
                  <div className="wpo-team-item">
                    <Link
                      to="/home"
                      className="wpo-team-img-wrap"
                      onClick={handleNavigate}
                    >
                      <div className="wpo-team-img">
                        <div className="wpo-team-img-inner">
                          <img src={story.sImg} alt="" />
                        </div>
                      </div>
                    </Link>
                    <div className="wpo-team-text">
                      <h3>
                        <Link
                          onClick={ClickHandler}
                          to={`/team-single/${story.slug}`}
                        >
                          {story.name}
                        </Link>
                      </h3>
                      <div className="wpo-story-content">
                        <span>{story.date}</span>
                        <h2>
                          <HashLink className="hashLink" to="/#eventForm">
                            {" "}
                            {story.title}
                          </HashLink>
                        </h2>
                        <p>{story.description}</p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
