import React from "react";




const BlogList = (props) => {
  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-12 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              {/* {blogs.slice(0, 3).map((blog, bitem) => ( */}
              <div className={`post  `}>
                {/* <div className="entry-media video-holder">
                                        <img src={blog.blogSingleImg} alt=""/>
                                        <VideoModal/>
                                    </div> */}
                {/* <div className="entry-meta">
                    <ul>
                      <li>
                        <i className="fi flaticon-user"></i> By{" "}
                        <Link
                          onClick={ClickHandler}
                          to={`/blog-single/${blog.slug}`}
                        >
                          {blog.authorTitle}
                        </Link>{" "}
                      </li>
                      <li>
                        <i className="fi flaticon-comment-white-oval-bubble"></i>{" "}
                        Comments {blog.comment}{" "}
                      </li>
                      <li>
                        <i className="fi flaticon-calendar"></i>{" "}
                        {blog.create_at}
                      </li>
                    </ul>
                  </div> */}
                <div className="entry-details">
                  <h3 className="text-center">
                    {/* <Link
                        onClick={ClickHandler}
                        to={`/blog-single/${blog.slug}`}
                      >
                        {blog.title}
                      </Link> */}
                    Ready to Make Memories?
                  </h3>
                  <p className="text-center">
                    GTS is more than just a caterer. Our specialty is providing
                    peace of mind. With our experienced and dedicated team, we
                    handle all the nuances of off-site catering and event
                    production, so you can enjoy your own event as a guest. From
                    first impressions to lasting impressions, our commitment is
                    to make every event a truly exceptional experience!
                  </p>
                  {/* <Link
                    onClick={ClickHandler}
                    to={`/blog-single/${blog.slug}`}
                    className="read-more"
                  >
                    READ MORE...
                  </Link> */}
                </div>
              </div>

              {/* ))} */}
              {/* <div className="pagination-wrapper pagination-wrapper-left"> */}
              {/* <ul className="pg-pagination">
                  <li>
                    <Link to="/blog-left-sidebar" aria-label="Previous">
                      <i className="fi ti-angle-left"></i>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/blog-left-sidebar">1</Link>
                  </li>
                  <li>
                    <Link to="/blog-left-sidebar">2</Link>
                  </li>
                  <li>
                    <Link to="/blog-left-sidebar">3</Link>
                  </li>
                  <li>
                    <Link to="/blog-left-sidebar" aria-label="Next">
                      <i className="fi ti-angle-right"></i>
                    </Link>
                  </li>
                </ul> */}
              {/* </div> */}
            </div>
          </div>
          {/* <BlogSidebar blLeft={props.blLeft} /> */}
        </div>
      </div>
    </section>
  );
};

export default BlogList;
