import React, { useState } from "react";

import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ladoo from "../../images/logo/Breakfast/ladoo.webp";
import kaju_katli from "../../images/logo/Breakfast/kaju_katli.webp";
import Badusha from "../../images/logo/Breakfast/Badusha.jpg";
import Mysurpa from "../../images/logo/Breakfast/Mysurpa.jpg";

// import LocationMap from "../../EventSection/Modal";
import Ashoka from "../../images/logo/snaks/Ashoka.jpeg";
import PattinamPakoda from "../../images/logo/snaks/PattinamPakoda.jpeg";
import Chutney from "../../images/logo/snaks/Chutney.jpeg";
import FilterCoffee from "../../images/logo/snaks/FilterCoffee.jpeg";
import ThinaiHalwa from "../../images/logo/snaks/ThinaiHalwa.jpeg";
import RavaKitchadi from "../../images/logo/snaks/RavaKitchadi.jpeg";
import MysoreBond from "../../images/logo/snaks/MysoreBonda.jpeg";
import HoneyChilliPotato from "../../images/logo/snaks/HoneyChilliPotato.jpeg";
import Paneer from "../../images/logo/snaks/Paneer-65.jpeg";
import PepperKaju from "../../images/logo/snaks/PepperKaju.jpeg";
import masalaTea from "../../images/logo/snaks/MasalaTea.jpeg";
import Sheer from "./Menus/Sheer";
import Chat from "./Menus/ChatStall";
import LocationMap from "../EventSection/Modal";
import RSVPFrom from "../RSVPFrom/RSVPFrom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import ShowCards from "../MainMenu/showCards";

const PortfolioSection = (props) => {
  const accordionId = "accordionExample";
  const [data, setData] = useState([
    { name: "Ashoka", active: false, img: Ashoka },
    {
      name: "Pattinam  Pakoda/ Kathampa Bhajji",
      active: false,
      img: PattinamPakoda,
    },
    { name: "Chutney", active: false, img: Chutney },

    { name: "Thinai Halwa", active: false, img: ThinaiHalwa },
    { name: "Rava Kitchadi", active: false, img: RavaKitchadi },
    { name: "Mysore Bonda", active: false, img: MysoreBond },
    { name: "Kaju Katli", active: false, img: kaju_katli },
    { name: "Honey Chilli Potato", active: false, img: HoneyChilliPotato },
    { name: "Paneer-65", active: false, img: Paneer },
    { name: "Pepper Kaju", active: false, img: PepperKaju },
    { name: "Filter Coffee", active: false, img: FilterCoffee },
    { name: "Masala Tea", active: false, img: masalaTea },
  ]);

  const [eveningSnacks, seteveningSnacks] = React.useState(
    JSON.parse(localStorage.getItem("evening"))
  );
  const [noonLunch, setNoonLunch] = React.useState(
    JSON.parse(localStorage.getItem("noon"))
  );
  console.log(noonLunch);
  const [menu, setMenu] = React.useState();

  function saveCheckboxValues(type) {
    const checkboxes = document.querySelectorAll(".noon");
    let checkedValues = [];

    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkedValues.push(checkbox.value);
      }
    });
    setNoonLunch(checkedValues);

    // if (checkboxes == true) {
    localStorage.setItem(type, JSON.stringify(noonLunch));

    // }
  }

  function eveningCheckboxValues(type) {
    const checkboxes1 = document.querySelectorAll(".evening");
    let checkedValues1 = [];

    checkboxes1.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkedValues1.push(checkbox.value);
      }
    });
    seteveningSnacks(checkedValues1);

    // if (checkboxes == true) {
    localStorage.setItem(type, JSON.stringify(eveningSnacks));

    // }
  }

  const handleSubmit = (type) => {
    saveCheckboxValues(type);
  };
  const handleEvening = (type) => {
    eveningCheckboxValues(type);
  };

  return (
    <section className="wpo-contact-pg-section ">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              {" "}
              <div className="row">
                <div class="accordion" id="accordionExample">
                  {/* <Slide direction="right">
                    <Sheer data={data} setData={setData} id={accordionId} />
                  </Slide>
                  <Slide direction="left">
                    <Chat data={data} setData={setData} id={accordionId} />
                  </Slide> */}
                  <ShowCards data={data} setData={setData} id={accordionId} />
                </div>
              </div>
            </div>
            {/* <div className="wpo-contact-title">
                          <h2>Have Any Question?</h2>
                          <p>It is a long established fact that a reader will be distracted
                              content of a page when looking.</p>
                      </div> */}
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col ">
          <div
            class="modal"
            id="myModal"
            // style={{ marginTop: "5rem" }}
          >
            <div class="modal-dialog modal-dialog-scrollable">
              <div class="modal-content ">
                <div class="modal-header">
                  <h4 class="modal-title">Please fill up the form</h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div class="modal-body">
                  <RSVPFrom />
                </div>

                <div class="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-contact-pg-section ">
        <div
          class="modal"
          id="myModal1"
          // style={{ marginTop: "5rem" }}
        >
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content ">
              <div class="modal-header">
                <h4 class="modal-title">Please fill up the form</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div class="modal-body">
                <RSVPFrom />
              </div>

              <div class="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
