import React, { useState } from "react";
import { Link } from "react-router-dom";

import pongal from "../../../images/logo/Breakfast/pongal.jpg";
import vada from "../../../images/logo/Breakfast/vada.webp";
import puriMasala from "../../../images/logo/Breakfast/pooriMasala.jpg";
import kothu from "../../../images/logo/Breakfast/kothu.jpg";
import OnionUttapam from "../../../images/logo/Breakfast/OnionUttapam.jpg";
import Idli from "../../../images/logo/Breakfast/Idli.jpg";
import cutney from "../../../images/logo/Breakfast/cutney.jpg";
import filterCoffee from "../../../images/logo/Breakfast/filterCoffee.jpg";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import ShowCards from "../../MainMenu/showCards";
import curdRice from "../../../images/logo/Breakfast/curd_rice.jpg";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import OnionRaitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import BeansPoriyal from "../../../images/logo/Breakfast/beans_poriyal.jpg";
import PotatoKarakari from "../../../images/logo/Breakfast/potato_karakari.jpg";
import OilBrinjal from "../../../images/logo/Breakfast/oil_brinjal.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vadai.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import Rasam from "../../../images/logo/Breakfast/rasam.jpg";
import SemiyalPaalPayasam from "../../../images/logo/Breakfast/semiya_paal_payasam.jpg";
import ThalichalMoor from "../../../images/logo/Breakfast/thalicha_moor.jpg";
import ParupuGhee from "../../../images/logo/Breakfast/parupu_ghee.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import chapathi from "../../../images/logo/Breakfast/chappathi.jpg";
import Cashew from "../../../images/logo/Breakfast/Cashew.jpg";
import AshokaHalwa from "../../../images/logo/Breakfast/ashoka_halwa.jpg";
import PaalBoli from "../../../images/logo/Breakfast/paal_boli.jpg";
import SpecialVadai from "../../../images/logo/Breakfast/special_vadai.jpg";
import VegBriyani from "../../../images/logo/Breakfast/veg_briyani.jpg";
import PaneerButterMasala from "../../../images/logo/Breakfast/paneer_butter_masala.jpg";
import OnionUthappam from "../../../images/logo/Breakfast/onion_uthappam.jpg";
import Malpua from "../../../images/logo/Breakfast/malpua.jpg";
import Cutlet from "../../../images/logo/Breakfast/cutlet.jpg";
import Pulav from "../../../images/logo/Breakfast/pulav.jpg";
import VegGravy from "../../../images/logo/Breakfast/veg_gravy.jpg";
import Phulka from "../../../images/logo/Breakfast/phulka.jpg";
import MushrroomMasala from "../../../images/logo/Breakfast/mushroom_masala.jpg";
import PodiUthappam from "../../../images/logo/Breakfast/podi_uthappam.jpg";
import HeartIdli from "../../../images/logo/Breakfast/heart_idli.jpg";
import AravaiSambar from "../../../images/logo/Breakfast/aravai_sambar.jpg";
import bagalaBath from "../../../images/logo/Breakfast/bagala_bath.jpg";

const ShopProduct = () => {
  const [sheer, setSheer] = useState(false);
  const [data, setData] = useState([
    {
      name: "Ashoka Halwa / Soft Halwa",
      active: false,
      img: AshokaHalwa,
    },
    { name: "Paal Boli / Bread Sweet", active: false, img: PaalBoli },
    {
      name: "Special Vadai / Bonda",
      active: false,
      img: SpecialVadai,
    },
    { name: "Veg Briyani", active: false, img: VegBriyani },
    { name: "Onion Raitha", active: false, img: OnionRaitha },
    {
      name: "Chappathi / Parotta",
      active: false,
      img: chapathi,
    },
    {
      name: "Paneer Butter Masala / Pepper Gravy",
      active: false,
      img: PaneerButterMasala,
    },
    { name: "Onion Uthappam / Ghee roast", active: false, img: OnionUthappam },
    { name: "Idli -3 Types Of Chutney", active: false, img: cutney },
    { name: "Sambar", active: false, img: sambhar },
    { name: "Curd Rice", active: false, img: curdRice },
    {
      name: "Cashew Halwa / Pineapple Kesari",
      active: false,
      img: Cashew,
    },
    { name: "Malpua / Jeera Boli", active: false, img: Malpua },
    { name: "Cutlet - Sauce", active: false, img: Cutlet },
    { name: "Pulav", active: false, img: Pulav },
    { name: "Veg Gravy", active: false, img: VegGravy },
    { name: "Phulka / Bun Parotta", active: false, img: Phulka },
    {
      name: "Mushroom Masala / Suraikai Gravy",
      active: false,
      img: MushrroomMasala,
    },
    { name: "Podi Uthappam / Masala Dosai", active: false, img: PodiUthappam },
    {
      name: "Heart Idli - 3 Types Of Chutney",
      active: false,
      img: HeartIdli,
    },
    { name: "Aravai Sambar", active: false, img: AravaiSambar },
    { name: "Bagala Bath", active: false, img: bagalaBath },
    { name: "Pickle", active: false, img: Pickle },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handleStandardBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("standardBreakfast", JSON.stringify(clickedValues));
  };

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default ShopProduct;
