import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import menu from "../../images/logo/menu.pdf";
import "./style.css";

const Scrollbar = () => {
  const onButtonClick = () => {
    const pdfUrl = menu;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "menu.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="col-lg-12">
        <div className="header-menu">
          <ul className="smothscroll">
            <li>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=918111077775&text=Hi%20GTS"
              >
                {" "}
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="header-menu">
          <ul className="brochure">
            <li>
              <span id="downloadIcon" onClick={onButtonClick}>
                {" "}
                <svg
                  style={{
                    width: "25px",
                    margin: "auto",
                    
                    cursor: "pointer",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </span>
              {/* <a target="_blank" href={menu}>
                <a id="item">CATALOGUE</a>
              </a> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Scrollbar;
