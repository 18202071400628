import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../images/logo/gallery19.jpg";
import img2 from "../../images/logo/mainHome.jpg";
import bg from "../../images/couple/image-bg.svg";
import { Fade, Zoom } from "react-awesome-reveal";

const CoupleSection2 = (props) => {
  return (
    <>
      <section className="" style={{ padding: "50px 0" }} id="couple">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            className="couple-img-wrap"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              // marginLeft: 80,
              justifyContent: "space-evenly",
            }}
          >
            <Zoom
              direction="up"
              duration="1200"
              triggerOnce="true"
              className=" home-couple-text"
            >
              <div style={{ maxWidth: 500 }} className="couple-text ">
                <h2 className="" style={{ fontWeight: 500 }}>
                  Trusted By 200 + Satisfied Clients
                </h2>
                <p className="" style={{ textAlign: "justify" }}>
                  'GTS Catering’ was established by Mr. Hari Thamarai Selvan, in
                  1993, on the land of Nagapattinam and extended widely through
                  Tamil Nadu. As we develop, we always remember where we
                  originated from and what we are attempting to accomplish.
                  <br />
                  <br />
                  We give distinctive foods to meet the most requesting desires
                  and tastes and make your events memorable with our flavours.
                  We offer unique and assorted menu to provide food. Be it to be
                  provided for your family or social occasion at your home,
                  office and so on. GTS catering is a stage to be!
                  <br />
                  <br />
                  It has been our arrangement to treat each customer with most
                  extreme honesty and the most noteworthy level of polished
                  methodology. This is the reason GTS Catering today is viewed
                  as a confided in name in the business.
                </p>
              </div>
            </Zoom>
            <Fade direction="up" duration="1200" triggerOnce="true">
              <div>
                <img
                  src={
                    "https://zerothindex.co.in/gts/assets/images/main/aboutprof.jpg"
                  }
                  style={{ borderRadius: "10px", width: "500px" }}
                  alt=""
                />
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="  " style={{ padding: "50px 0" }} id="couple">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            className="couple-img-wrap rm-m"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "80%",
              // marginLeft: 80,
              justifyContent: "space-evenly",
            }}
          >
            <Fade direction="up" duration="1200" triggerOnce="true">
              <div>
                <img
                  src={
                    "https://zerothindex.co.in/gts/assets/images/main/home1.jpg"
                  }
                  style={{
                    borderRadius: "10px",
                    width: "500px",
                    marginTop: "15px",
                  }}
                  alt=""
                />
              </div>
            </Fade>
            <Zoom
              direction="up"
              duration="1200"
              triggerOnce="true"
              className=" home-couple-text"
            >
              <div
                style={{ maxWidth: 500, marginTop: "15px" }}
                className="couple-text "
              >
                <h2 className="" style={{ fontWeight: 500 }}>
                  GTS Catering Service
                </h2>
                <p className="" style={{ textAlign: "justify" }}>
                  At the heart of GTS Catering Service is our unwavering
                  dedication to quality, creativity, and customer satisfaction.
                  We believe that every event is unique, and therefore, deserves
                  a bespoke culinary experience tailored to its specific needs
                  and preferences. Whether it's an intimate gathering, a
                  corporate function, or a grand celebration, we approach each
                  event with meticulous attention to detail and a commitment to
                  exceeding expectations.
                </p>
              </div>
            </Zoom>
          </div>
        </div>
      </section>
      {/* <section className="wpo-couple-section ">
        <div className="container-fluid">
          <div className="couple-area clearfix">
            <div className="row align-items-center">
              <div className="col col-md-12 col-12">
                <div className="couple-item  ">
                  <div className="couple-img-wrap">
                    <Fade direction="up" duration="1200" triggerOnce="true">
                      <div className="couple-img">
                        <img src={img1} alt="" />
                      </div>
                    </Fade>
                  </div>
                  <Zoom
                    direction="up"
                    duration="1200"
                    triggerOnce="true"
                    className=" home-couple-text"
                    style={{ transform: "translateY(80px)" }}
                  >
                    <div className="couple-text ">
                      <p className="">
                        Just Tell us your choice of food menu and make your
                        tongue feel the taste. We has come a long way since its
                        establishment in the year 1993. We are the prominent
                        service providers in the catering industry.
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-1">
          <svg
            viewBox="0 0 1920 692"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.1">
              <path
                className="stroke-color"
                d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
                stroke=""
                strokeWidth="2"
              />
              <path
                d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="2"
              />
            </g>
            <g opacity="0.1">
              <path
                className="stroke-color"
                d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
                stroke=""
                strokeWidth="2"
              />
              <path
                d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="2"
              />
            </g>
           
            <path
              className="fill-color"
              d="M1041 397C1418.6 54.5998 1785 31.6665 1921 62.9998V649C1340.2 636.2 1092.33 475.667 1041 397Z"
              fill=""
            />
          </svg>
        </div>
      </section> */}
      {/* <section className="wpo-couple-section  " id="couple">
        <div className="container-fluid">
          <div className="couple-area clearfix">
            <div className="row align-items-center">
              <div className="col col-md-12 col-12">
                <div className="couple-item ">
                  <Fade direction="up" duration="1200" triggerOnce="true">
                    <div className="couple-img">
                      <img src={img2} alt="" />
                    </div>
                  </Fade>
                  <Zoom
                    direction="up"
                    duration="1200"
                    triggerOnce="true"
                    style={{ transform: "translateY(80px)" }}
                  >
                    <div className="couple-text">
                      {/* <h3> Lorem ipsum </h3> 
                      <p>
                        Our menu style varies depends on the guest , function
                        theme and preferences. We are the best and highly
                        demanded vegetarian catering service in Nagapattinam.
                        Our food is inspired through the use of carefully
                        selected ingredients.
                      </p>
                    </div>
                  </Zoom>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-1">
          <svg
            viewBox="0 0 1920 692"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.1">
              <path
                className="stroke-color"
                d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
                stroke=""
                strokeWidth="2"
              />
              <path
                d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="2"
              />
            </g>
            <g opacity="0.1">
              <path
                className="stroke-color"
                d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
                stroke=""
                strokeWidth="2"
              />
              <path
                d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="2"
              />
            </g>
            <path
              className="fill-color"
              d="M879 397C501.4 54.5998 135 31.6665 -1 62.9998V649C579.8 636.2 827.667 475.667 879 397Z"
              fill=""
            />
            {/* <path
              className="fill-color"
              d="M1041 397C1418.6 54.5998 1785 31.6665 1921 62.9998V649C1340.2 636.2 1092.33 475.667 1041 397Z"
              fill=""
            /
          </svg>
        </div>
      </section> */}
    </>
  );
};

export default CoupleSection2;
