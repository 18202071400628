import React, { useState } from "react";
import ShowCards from "../../MainMenu/showCards";
import PaneerJamun from "../../../images/logo/Breakfast/paneer_jamun.jpg";
import kalkanduPongal from "../../../images/logo/Breakfast/kalkandu_pongal.jpg";
import KismissRaitha from "../../../images/logo/Breakfast/kismis_raitha.jpg";
import DragonFruitJam from "../../../images/logo/Breakfast/dragon_fruit_jam.jpg";
import CarrotCapsicumPattaniPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import BrocolliMasalaKari from "../../../images/logo/Breakfast/brocolli_masalakari.jpg";
import MushroomPallipalayam from "../../../images/logo/Breakfast/mushroom_pallipalayam.jpg";
import VendaikaiNilakadalaiPulimandi from "../../../images/logo/Breakfast/vendaikai_nilakadalai_pulimandi.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import CurdVadai from "../../../images/logo/Breakfast/curd_vadai.jpg";
import RambuttanPickle from "../../../images/logo/Breakfast/rambuttan_pickle.jpg";
import ChappathiDalMakhani from "../../../images/logo/Breakfast/chappathi_dal_makhani.jpg";
import karupeypillaiRice from "../../../images/logo/Breakfast/karupeypillai_rice.jpg";
import VadakaThuvayal from "../../../images/logo/Breakfast/vadaka_thuvayal.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import Sambar from "../../../images/logo/Breakfast/sambhar.webp";
import VendhayaThokku from "../../../images/logo/Breakfast/endhaya_thokku.jpg";
import MoorKulambu from "../../../images/logo/Breakfast/moor_kulambu.jpg";
import NellikaiRasam from "../../../images/logo/Breakfast/nellikai_rasam.jpg";
import ElaneerPayasam from "../../../images/logo/Breakfast/elaneer_payasam.jpg";
import PotCurd from "../../../images/logo/Breakfast/pot_curd.jpg";
import Ghee from "../../../images/logo/Breakfast/ghee.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import palm from "../../../images/logo/Breakfast/palm.jpg";
import kavuni from "../../../images/logo/Breakfast/kavuni.webp";
import Rava_Dosa from "../../../images/logo/Breakfast/Rava-Dosa.jpg";
import Kulipaniyaram from "../../../images/logo/Breakfast/Kulipaniyaram.jpg";
import mysore_masala_dosa from "../../../images/logo/Breakfast/mysore-masala-dosa.webp";
import Multi_Millet_Idli from "../../../images/logo/Breakfast/Multi-Millet-Idli.jpg";
import idli_podi from "../../../images/logo/Breakfast/idli-podi.jpg";
import Chidambaram_Ghosthu from "../../../images/logo/Breakfast/Chidambaram_Ghosthu.jpg";
import vada from "../../../images/logo/Breakfast/vada.webp";
import pongal from "../../../images/logo/Breakfast/pongal.jpg";
import puriMasala from "../../../images/logo/Breakfast/pooriMasala.jpg";
import cutney from "../../../images/logo/Breakfast/cutney.jpg";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import filterCoffee from "../../../images/logo/Breakfast/filterCoffee.jpg";
import Unniappam from "../../../images/logo/Breakfast/unniyappam.webp";
import Idiyappam from "../../../images/logo/Breakfast/idiyappam.webp";

const Luxury = () => {
  const [data, setData] = useState([
    { name: "Karupatti Halwa / Palm Sugar Halwa", active: false, img: palm },
    { name: "Kada Prasadam/ Aadi Kumayam", active: false, img: kavuni },
    {
      name: "Unniappam / Kantharapam / Vellai Appam",
      active: false,
      img: Unniappam,
    },
    {
      name: "Paal Kolukattai / Pudi Kolukattaai",
      active: false,
      img: Kulipaniyaram,
    },
    {
      name: "Idiyappam / Appam – Coconut Milk / Stew / Veg Paya",
      active: false,
      img: Idiyappam,
    },
    { name: "Vada / Sambar Vada / MilaguVadai", active: false, img: vada },
    {
      name: "Pongal  / Paal Pongal / Five Rice Pongal",
      active: false,
      img: pongal,
    },
    {
      name: "Pudina Poori / Palak Poori / Beetroot Poori / Chola Poori – Rajma Masala/ Channa Masala",
      active: false,
      img: puriMasala,
    },
    { name: "Butter Rava Dosai – Kadapa", active: false, img: Rava_Dosa },
    {
      name: "Chettinadu Kari Dosai – Sundal Masala",
      active: false,
      img: mysore_masala_dosa,
    },
    {
      name: "Benne Idli / Millet Idli / Hear Idli",
      active: false,
      img: Multi_Millet_Idli,
    },
    { name: "4 Types Of Chutney", active: false, img: cutney },
    { name: "Spl. Podi With GingellyOil", active: false, img: idli_podi },
    // { name: "Chidambaram Ghosthu", active: false, img: Chidambaram_Ghosthu },
    { name: "Sambar", active: false, img: sambhar },
    { name: "Coffee Stall With Pettikadai", active: false, img: filterCoffee },
  ]);

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };
  const [number, setCount] = useState(8);
  const [buttonActive, setButtonState] = useState(false);

  return (
    <>
      <ShowCards data={data} setData={setData} />
    </>
  );
};

export default Luxury;
