import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";

import AboutPage from "../AboutPage/AboutPage";

import GalleryPage from "../GalleryPage/GalleryPage";

import PortfolioGridPage from "../PortfolioGridPage/PortfolioGridPage";

import BlogPage from "../BlogPage/BlogPage";

import ContactPage from "../ContactPage/ContactPage";

import ErrorPage from "../ErrorPage/ErrorPage";

import Breakfast from "../ShopPage/Breakfast";
import Snacks from "../ShopPage/Snacks";
import Dinner from "../ShopPage/Dinner";
import Lunch from "../ShopPage/Lunch";
import SpecialMenus from "../PortfolioGridPage/specialMenu";
import TestMonials from "../testmonials";
import ChartStall from "../ShopPage/ChartStall";
import WelcomeDrinks from "../ShopPage/WelcomeDrinks";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="/testmonials" element={<TestMonials />} />

          <Route path="about" element={<AboutPage />} />

          <Route path="gallery" element={<GalleryPage />} />

          {/* <Route path="/menus" element={<SpecialMenus />} /> */}

          <Route path="/breakfast" element={<Breakfast />} />
          <Route path="/snacks" element={<PortfolioGridPage />} />

          <Route path="/dinner" element={<Dinner />} />
          <Route path="/lunch" element={<Lunch />} />
          <Route path="/specialMenus" element={<SpecialMenus />}></Route>
          <Route path="/chatStall" element={<ChartStall />}></Route>
          <Route path="/welcomeDrinks" element={<WelcomeDrinks />}></Route>

          <Route path="/event" element={<BlogPage />} />

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/404" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
