import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
const RSVPFrom = ({ selectedMenu }) => {
  const [status, setStatus] = useState(undefined);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    guest: "",
    other: "",
    date: "",
    location: "",
    venue: "",
    otherMeal: "",
    meal: "",
    comments: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let s = "";
    selectedMenu?.map((ele) => {
      if (ele.active == true) {
        s += ele.name + ",";
      }
    });
    const data = {
      name: forms.name,
      email: forms.email,
      phone: forms.phone,
      guest: forms.guest,
      other: forms.other,
      date: startDate,
      location: forms.location,
      venue: forms.venue,
      otherMeal: forms.otherMeal,
      meal: forms.meal,
      comments: forms.comments,
      selectedMenu: s,
    };
    setStatus({ type: "process" })
    await axios
      .post(`https://gtscatering.com/Email/Event.php`, data)
      .then(
        (res) =>{ 
          
        setStatus({ type: "success" })
        setTimeout(function () {
          window.location.reload(1);
        }, 3000)
      }
      )
      .catch((err) => console.log(err));
  };
  const [startDate, setStartDate] = useState(new Date());

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Name "
              required
            />
            {/* {validator.message("name", forms.name, "required|alpha_space")} */}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your Email"
              required
            />
            {/* {validator.message("email", forms.email, "required|email")} */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="number"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Contact Number"
              required
            />
            {/* {validator.message("name", forms.name, "required|alpha_space")} */}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              value={forms.guest}
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              type="text"
              className="form-control "
              name="guest"
              required
            >
              <option value="" disabled selected hidden>Event Type</option>
              <option value="wedding">Wedding</option>
              <option value="Reception">Reception</option>
              <option value="Engagement">Engagement</option>
              <option value="Baby Shower">Baby Shower</option>
              <option value="Ear Piercing">Ear Piercing</option>
              <option value="Get Together">Get Together</option>
              <option value="Business Meeting">Business Meeting</option>

              <option value="Sashtiapthapoorthi (60th Marriage)">
                Sashtiapthapoorthi (60th Marriage)
              </option>
              <option>
                Other:{" "}
                <input
                  value={forms.other}
                  type="text"
                  name="other"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  className="form-control"
                  placeholder="Event Date"
                />
              </option>
            </select>
            {/* {validator.message("guest", forms.guest, "required")} */}
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <DatePicker
              className="text-secondary"
              selected={startDate}
              name="date"
              onChange={(date) => setStartDate(date)}
              required
            />

            {/* {validator.message("reason", forms.email, "required")} */}
          </div>
        </div>{" "}
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.location}
              type="text"
              name="location"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Event Location"
              required
            />
            {/* {validator.message("reason", forms.email, "required")} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.venue}
              type="text"
              name="venue"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Venue"
              required
            />
            {/* {validator.message("reason", forms.email, "required")} */}
          </div>
        </div>{" "}
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              value={forms.meal}
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              type="text"
              className="form-control"
              name="meal"
              required
            >
              <option value="" disabled selected hidden>No. of Pax</option>
              <option value="Up To 500 pax">Up To 500 pax</option>
              <option value="500 - 1000 pax">500 - 1000 pax</option>
              <option value="1000 - 1500 pax">1000 - 1500 pax</option>
              <option value="1500 - 2000 pax">1500 - 2000 pax</option>
              <option value="Above 2000 pax">Above 2000 pax</option>
              <option>
                Other:{" "}
                <input
                  value={forms.otherMeal}
                  type="text"
                  name="otherMeal"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  className="form-control"
                  placeholder="Event Date"
                />
              </option>
            </select>
            {/* {validator.message("meal", forms.meal, "required")} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.comments}
              type="text"
              name="comments"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Comments"
              required
            />
            {/* {validator.message("reason", forms.email, "required")} */}
          </div>
        </div>
      </div>

      <div className="submit-area">
        <button
          type="submit"
          className={`theme-btn ${status?.type == "success" ? "disabled" : ""}`}
        >
          {status?.type === "process"?"Submitting..":"Submit"} 
        </button>
      </div>
      {status?.type === "success" && (
        <div className="submit-area">
          <a
            style={{ padding: "10px", fontSize: "20px", color: "green" }}
            className="submitSuccess"
          >
            {" "}
            Form Submitted successfully...
          </a>
        </div>
      )}
    </form>
  );
};

export default RSVPFrom;
