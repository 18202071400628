import React, { useState } from "react";
import { Link } from "react-router-dom";
import RSVPFrom from "../RSVPFrom/RSVPFrom";

const ShowCards = ({ data, setData }) => {
  const [sheer, setSheer] = useState(false);
  //   const [data,setData]=useState([{name:"Karupatti Halwa / Palm Sugar Halwa",active:false,img:puriMasala},{name:"Kavuni Arisi/ Moongil Arisi Akkara Vadisal",active:false,img:puriMasala},{name:"Pal Kolukattai / Pudi Kolukattai / Kulipaniyaram",active:false,img:puriMasala},{name:"Vada",active:false,img:puriMasala},{name:"Pongal",active:false,img:puriMasala},{name:"Poori - Masala",active:false,img:puriMasala},{name:"Butter Rava Dosai",active:false,img:puriMasala},{name:"Chettinadu Kari Dosai / Mysore Masal Dosai ",active:false,img:puriMasala},{name:"Mini Idly / ThattuIdly / Millet Idly",active:false,img:puriMasala},{name:"3 Types Of Chutney",active:false,img:puriMasala},{name:"Spl. Podi With GingellyOil",active:false,img:puriMasala},{name:"Chidambaram Ghosthu ",active:false,img:puriMasala}])

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  return (
    <>
      <section className="wpo-product-section section-padding ">
        <div class="accordion-body">
          <div className="d-flex  justify-content-between justify-content-sm-end mb-3 me-5">
            <button className="button " onClick={() => setSheer(!sheer)}>
              Select Menu:
            </button>
            {/* Mobile View eye button */}
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#myModal2"
              className="d-sm-none "
              style={{ border: "none", background: "none" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-eye"
                style={{ color: "#592c87" }}
              >
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
            </button>
          </div>
          <div
            className={` d-flex justify-content-center justify-content-sm-around rows-10`}
          >
            {" "}
            <div className="d-flex">
              <div className="d-flex wpo-product-wrap">
                <div className="d-flex flex-wrap d-flex  justify-content-around overflow-hidden ">
                  {data.map((ele, i) => {
                    return (
                      <>
                        <div
                          style={
                            sheer
                              ? {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                              : {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                          }
                        >
                          <div className="wpo-product-item">
                            <div
                              className="wpo-product-img"
                              style={{
                                minWidth: 200,
                                maxWidth: 200,
                                minHeight: 200,
                                maxHeight: 200,
                              }}
                            >
                              <img
                                src={ele.img}
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            </div>
                            <div className="wpo-product-text">
                              <h3> {ele.name}</h3>
                              {sheer ? (
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-html="true"
                                  type="submit"
                                  value={ele.name}
                                  onClick={(e) => {
                                    handleSheer(i);
                                  }}
                                  className="standardBreakfast button"
                                  title="Add to Cart"
                                >
                                  {ele.active ? "Unselect" : "Select"}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {!sheer ? null : (
                  <div
                    className="position-relative d-none d-sm-block"
                    style={
                      sheer
                        ? {
                            minWidth: 250,
                            maxWidth: 250,
                            margin: "0 10px",
                          }
                        : {
                            minWidth: 300,
                            maxWidth: 300,
                            margin: "0 10px",
                          }
                    }
                  >
                    <div
                      className="office-info-item wpo-product-item position-sticky "
                      style={{ top: "89px" }}
                    >
                      <div className="">
                        <h4>Select Menu:</h4>
                      </div>
                      <div className=" justify-content-between ">
                        <ul>
                          {" "}
                          {data?.map((item, index) => {
                            if (item.active == false) return;
                            return (
                              <li>
                                {console.log(item)} {item.name}{" "}
                                <a
                                  key={index}
                                  onClick={() => handleSheer(index)}
                                >
                                  <i class="fa fa-trash-o"></i>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal1"
                          className="theme-btn"
                        >
                          Submit
                        </button>
                      </div>{" "}
                      {/* <LocationMap /> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="wpo-contact-pg-section ">
        {/* Desktop View Modal */}
        <div
          class="modal"
          id="myModal1"
          // style={{ marginTop: "5rem" }}
        >
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content ">
              <div class="modal-header">
                <h4 class="modal-title">Please fill up the form</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div class="modal-body">
                <RSVPFrom selectedMenu={data} />
              </div>

              <div class="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* Mobile View modal */}
        <div
          class="modal"
          id="myModal2"
          // style={{ marginTop: "5rem" }}
        >
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content ">
              <div class="modal-header">
                <h4 class="modal-title">Select Menu:</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div class="modal-body">
                <ul>
                  {" "}
                  {data?.map((item, index) => {
                    if (item.active == false) return;
                    return (
                      <li>
                        {console.log(item)} {item.name}{" "}
                        <a key={index} onClick={() => handleSheer(index)}>
                          <i class="fa fa-trash-o"></i>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div class="modal-footer">
                <div className="submit-area">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal1"
                    className="theme-btn"
                    disabled={data?.length ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCards;
