import React, { useState } from "react";
import Jamun from "../../../images/logo/Breakfast/kova_gulab_jamun.webp";
import sambhar from "../../../images/logo/Breakfast/sambhar.webp";
import Raitha from "../../../images/logo/Breakfast/onion_raita.jpg";
import FruitJam from "../../../images/logo/Breakfast/fruit_jam.jpg";
import CarrotCapsicumPoriyal from "../../../images/logo/Breakfast/carrot_capsicum_poriyal.jpg";
import SenaiChops from "../../../images/logo/Breakfast/senai_chops.jpg";
import CauliflowerMasalaKari from "../../../images/logo/Breakfast/cauliflower_masala_kari.jpg";
import MalabarAviyal from "../../../images/logo/Breakfast/malabar_aviyal.jpg";
import ValaiPooChips from "../../../images/logo/Breakfast/valai_poo_chips.jpg";
import MasalaVada from "../../../images/logo/Breakfast/masala_vada.jpg";
import Pickle from "../../../images/logo/Breakfast/pickle.jpg";
import Briyani from "../../../images/logo/Breakfast/briyani.jpg";
import Thalcha from "../../../images/logo/Breakfast/thalcha.jpg";
import Rice from "../../../images/logo/Breakfast/rice.webp";
import VathalKulambu from "../../../images/logo/Breakfast/vathal_kulambu.jpg";
import PineappleRasam from "../../../images/logo/Breakfast/pineapple_rasam.jpg";
import Curd from "../../../images/logo/Breakfast/curd.jpg";
import AdaiPrathamPayasam from "../../../images/logo/Breakfast/adai_pratham_payasam.jpg";
import Parupu from "../../../images/logo/Breakfast/parupu.jpg";
import Appalam from "../../../images/logo/Breakfast/appalam.jpg";
import brinjal from "../../../images/logo/Breakfast/brinjalmilagaiMandi.webp"

const Premium = () => {
  const [sheer, setSheer] = React.useState(false);
  const [data, setData] = useState([
    { name: "Makkann Beda / Kala Jamun", active: false, img: Jamun },
    {
      name: "Onion Raitha",
      active: false,
      img: Raitha,
    },
    {
      name: "Fruit Jam",
      active: false,
      img: FruitJam,
    },
    {
      name: "Carrot Capsicum Poriyal / Beans Parupu Usuli",
      active: false,
      img: CarrotCapsicumPoriyal,
    },
    { name: "Senai Chops", active: false, img: SenaiChops },
    {
      name: "Cauliflower MasalaKari",
      active: false,
      img: CauliflowerMasalaKari,
    },
    { name: "Malabar Aviyal", active: false, img: MalabarAviyal },
    { name: "Brinjal Milagai Mandi", active: false, img: brinjal }, //noon-lunch
    {
      name: "Valai Poo Chips",
      active: false,
      img: ValaiPooChips,
    },
    {
      name: "Masal Vadai / Navathaniya Vadai",
      active: false,
      img: MasalaVada,
    },
    { name: "Pickle", active: false, img: Pickle },
    { name: "Briyani / Pulav", active: false, img: Briyani },
    { name: "Gravy / Thalcha", active: false, img: Thalcha },
    { name: "Rice", active: false, img: Rice },
    { name: "Sambar", active: false, img: sambhar },
    {
      name: "Vathal Kulambu / Parupu UrundaiKulambu",
      active: false,
      img: VathalKulambu,
    },
    { name: "Pineapple Rasam", active: false, img: PineappleRasam },
    { name: "Curd", active: false, img: Curd },
    { name: "Adai Pratham Payasam", active: false, img: AdaiPrathamPayasam },
    { name: "Parupu", active: false, img: Parupu },
    { name: "Appalam", active: false, img: Appalam },
  ]);

  const handleSheer = (i) => {
    if (data[i].active == true) data[i].active = false;
    else data[i].active = true;

    setData([...data]);
  };

  const clickedValues = [];
  const handlePremiumBreakfast = (e) => {
    console.log(e.target.value);
    const buttonValue = e.target.value;
    clickedValues.push(buttonValue);

    console.log(clickedValues);
    localStorage.setItem("premiumBreakfast", JSON.stringify(clickedValues));
  };

  return (
    <>
      <section className="wpo-product-section section-padding">
        <div class="accordion-body">
          <div className="d-flex flex-row-reverse ">
            <button className="button " onClick={() => setSheer(!sheer)}>
              Select Menu:
            </button>
          </div>
          <div className={` d-flex justify-content-around rows-10`}>
            {" "}
            <div className="d-flex">
              <div className="d-flex wpo-product-wrap">
                <div className="d-flex flex-wrap d-flex justify-content-around overflow-hidden ">
                  {data.map((ele, i) => {
                    return (
                      <>
                        <div
                          style={
                            sheer
                              ? {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                              : {
                                  minWidth: 300,
                                  maxWidth: 300,
                                  margin: "0 10px",
                                }
                          }
                        >
                          <div className="wpo-product-item">
                            <div className="wpo-product-img">
                              <img src={ele.img} alt="" />
                            </div>
                            <div className="wpo-product-text">
                              <h3> {ele.name}</h3>
                              {sheer ? (
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-html="true"
                                  type="submit"
                                  value={ele.name}
                                  onClick={(e) => {
                                    handleSheer(i);
                                  }}
                                  className="standardBreakfast button"
                                  title="Add to Cart"
                                >
                                  {ele.active ? "Unselect" : "Select"}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {!sheer ? null : (
                  <div
                    className="position-relative"
                    style={
                      sheer
                        ? { minWidth: 250, maxWidth: 250, margin: "0 10px" }
                        : { minWidth: 300, maxWidth: 300, margin: "0 10px" }
                    }
                  >
                    <div
                      className="office-info-item wpo-product-item position-fixed "
                      style={{ top: "280px" }}
                    >
                      <div className="">
                        <h4>Select Menu:</h4>
                      </div>
                      <div className=" justify-content-between ">
                        <ul>
                          {" "}
                          {data?.map((item, index) => {
                            if (item.active == false) return;
                            return (
                              <li>
                                {console.log(item)} {item.name}{" "}
                                <a
                                  key={index}
                                  onClick={() => handleSheer(index)}
                                >
                                  <i class="fa fa-trash-o"></i>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal1"
                          className="theme-btn"
                        >
                          Submit
                        </button>
                      </div>{" "}
                      {/* <LocationMap /> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Premium;
